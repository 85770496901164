import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectTgAttribute,
  unSelectTgAttribute,
} from "../../actions/campaign-planning/CampaignPlanningActions";

// Utils And Constants
import { HeaderWithActionButton } from "./PlanningUtils";
import { DummyPoiLayers } from "./TabConstants";

// Components
import CheckBox from "../../../components/check-box/CheckBox";

// construct Resulting PoiLayers
function constructResPoiLayers(selectedTgAttributesMap = {}) {
  const totalAttrSelectedCount = Object.values(selectedTgAttributesMap).reduce(
    (acc, curr) => {
      const attributesLength = curr.length;
      if (attributesLength > 0) {
        acc = acc + attributesLength;
      }
      return acc;
    },
    0
  );

  // return poiLayers
  if (totalAttrSelectedCount <= 2) {
    return DummyPoiLayers.slice(0, 3);
  }

  if (totalAttrSelectedCount <= 4) {
    return DummyPoiLayers.slice(1, 6);
  }

  if (totalAttrSelectedCount <= 7) {
    return DummyPoiLayers.slice(4, 10);
  }

  // default return all dummy layers
  return DummyPoiLayers;
}

// get Selected Tg Attributes
function getSelectedTgAttributes(targetGroupData = [], selectedTgId = "") {
  const tgAttributes = [];

  if (targetGroupData.length === 0 || !selectedTgId) {
    return tgAttributes;
  }

  targetGroupData.forEach((tg) => {
    const { id, attributes = [] } = tg;
    if (id === selectedTgId) {
      tgAttributes.push(...attributes);
    }
  });

  return tgAttributes;
}

// Target Groups List
function TargetGroupsList({
  targetGroupData = [],
  selectedTgAttributesMap = {},
}) {
  const dispatch = useDispatch();

  // States
  const [selectedTgId, setSelectedTgId] = useState("");
  const [selectedTgName, setSelectedTgName] = useState("");

  // tg attr for selected tg
  const tgAttrForTgSelected = selectedTgAttributesMap[selectedTgId] || [];
  const selectedTgAttrIds = tgAttrForTgSelected.map(
    (tgAttr) => tgAttr.tgAttrId
  );

  // selected Tg Attributes
  const selectedTgAttributes = getSelectedTgAttributes(
    targetGroupData,
    selectedTgId
  );

  // handle select tg
  function handleSelectTg(id, name) {
    setSelectedTgId(id);
    setSelectedTgName(name);
  }

  function handleSelectTgAttr(e, id, name) {
    const { checked } = e.target;

    // construct tg attribute
    const tgAttribute = {
      tgId: selectedTgId,
      tgName: selectedTgName,
      tgAttrId: id,
      tgAttrName: name,
    };

    // dispatch to select/unselect
    const dispatchFn = checked ? selectTgAttribute : unSelectTgAttribute;
    dispatch(dispatchFn(tgAttribute));
  }

  return (
    <div className="w-100 d-flex">
      <div className="col-6 px-0">
        <div className="px-3">
          {targetGroupData.map((targetGroup) => {
            const { id, name, attributes = [] } = targetGroup;
            const isTgSelected = selectedTgId === id;
            const selectedClassName = isTgSelected ? "bg-alt rounded-lg" : "";
            const isTgAttributesPresent = attributes.length > 0;
            return (
              <div
                key={id}
                className={`d-flex align-items-center justify-content-between p-2 cursor-pointer ${selectedClassName}`}
                onClick={() => handleSelectTg(id, name)}
              >
                <p className="mb-0">{name}</p>
                {isTgAttributesPresent && (
                  <i
                    className={`fa ${
                      isTgSelected
                        ? "fa-angle-down text-primary"
                        : "fa-angle-right"
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Tg Attributes */}
      {selectedTgId && (
        <div className="col-6 border-left">
          <h5 className="mb-0 p-2 ">{selectedTgName}</h5>
          <hr className="mt-0 mb-2" />
          {selectedTgAttributes.map((attr) => {
            const { id, name } = attr;
            return (
              <div
                key={id}
                className="d-flex align-items-center justify-content-between p-2 cursor-pointer"
              >
                <p className="mb-0">{name}</p>
                <CheckBox
                  boxStyle="mb-0"
                  inputBoxStyle="mt-0"
                  checkBoxKey={id}
                  showLabel={false}
                  checked={selectedTgAttrIds.includes(id)}
                  onChange={(e, id) => handleSelectTgAttr(e, id, name)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

/**
 * TG TAB
 */
export default function TargetGroupTab({ handleTabHeaderBtnClick = () => {} }) {
  // tg data (hardcode in reducer for now)
  const targetGroupData = useSelector(
    (state) => state.campaignPlanning.targetGroupData
  );

  // selected tg attr map
  const selectedTgAttributesMap = useSelector(
    (state) => state.campaignPlanning.selectedTgAttributesMap
  );
  const isTgSelected = Object.keys(selectedTgAttributesMap).length > 0;

  // heatmap loading
  const heatmapLoading = useSelector(
    (state) => state.geoData.poiTypeLayerDataLoading
  );

  // handle Heatmap Button Click
  function handleGenerateHeatmapClick() {
    // hardcoded dummy layers
    const resPoiLayers = constructResPoiLayers(selectedTgAttributesMap);

    // callback fn
    handleTabHeaderBtnClick(resPoiLayers);
  }

  return (
    <>
      {/* Header Section */}
      <HeaderWithActionButton
        title="Target Group"
        buttonText="Generate Heatmap"
        onButtonClick={handleGenerateHeatmapClick}
        disabled={!isTgSelected}
        buttonLoading={heatmapLoading}
      />

      {/* List */}
      <TargetGroupsList
        targetGroupData={targetGroupData}
        selectedTgAttributesMap={selectedTgAttributesMap}
      />
    </>
  );
}
