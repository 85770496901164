import { delay, put, select } from "redux-saga/effects";

// Apis
import { getCampaignMedias } from "../../../apis/CampaignMediaAPI";
import { getCampaign } from "../../../apis/CampaignAPI";
import { getCampaignPlan } from "../../../apis/CampaignPlanAPI";

// Constants & Utils
import { CPM } from "../../../constants/PriceMode";
import { ActionTypes } from "../../../constants/ActionConstants";
import { getDifferenceInDaysOfDateObject } from "../../../common-utils/date-utils/DateUtils";
import { defaultPagination } from "../../../constants/UrlConstants";

// get Submission Impact Action
export function getSubmissionImpactAction(costType, remove = false) {
  if (!remove) {
    return costType === CPM
      ? ActionTypes.SubmissionImpact.ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT
      : ActionTypes.SubmissionImpact
          .ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT;
  }

  return costType === CPM
    ? ActionTypes.SubmissionImpact.REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT
    : ActionTypes.SubmissionImpact
        .REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT;
}

// getSubmissionImpactPayload
export function getSubmissionImpactPayload(
  priceMode,
  mediaId,
  otsSplit,
  cost,
  duration
) {
  if (priceMode === CPM) {
    return { mediaId, otsSplit, duration };
  }
  return { mediaId, otsSplit, cost, duration };
}

// get Duration
export function getDuration(media) {
  const { startTimestamp, endTimestamp } = media;
  const dateObj = {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
  return getDifferenceInDaysOfDateObject(dateObj);
}

// update Submission Impact For CampaignMedias
export function* updateSubmissionImpactForCampaignMedias(response = {}) {
  const { items = [] } = response || {};

  // Wait till campaignPlan is loaded
  let campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);
  while (!campaignPlan) {
    yield delay(1000);
    campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);
  }

  const roadStretchOtsMap = campaignPlan.roadStretchOtsMap;

  for (const media of items) {
    const stretchOtsDetails = roadStretchOtsMap[media.roadStretchId] || {};
    const { genericOts, genericOtsLit, targetOts, targetOtsLit } =
      stretchOtsDetails;

    yield put({
      type: getSubmissionImpactAction(media.priceMode),
      payload: getSubmissionImpactPayload(
        media.priceMode,
        media.mediaId,
        { genericOts, genericOtsLit, targetOts, targetOtsLit },
        media.sellerPrice,
        getDuration(media)
      ),
    });
  }
}

// update CampaignMedias And CampaignDetails (CampaignBasicDetails & CampaignPlanDetails)
export function* updateCampaignMediasAndCampaignDetails(campaignId, cityId) {
  // pagination
  const pageNo = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSize;

  // CampaignMedias api
  // ---------------------------------------------------------------------
  const response = yield getCampaignMedias(campaignId, pageNo, pageSize);

  // update submission impact
  yield updateSubmissionImpactForCampaignMedias(response);

  // campaign medias success
  yield put({
    type: ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA_SUCCESS,
    payload: { campaignMedia: response.items },
  });

  // Basic Campaign Details
  // ------------------------------------------------------------------
  const campaignDetails = yield getCampaign(campaignId);
  yield put({
    type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_SUCCESS,
    payload: { campaignDetails },
  });

  // Campaign Plan Details
  // -----------------------------------------------------------------
  const campaignPlan = yield getCampaignPlan(campaignId, cityId);
  yield put({
    type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_SUCCESS,
    payload: { campaignPlan },
  });
}
