import React, { useState } from "react";

// Components
import CheckBox from "../check-box/CheckBox";

// CSS
import "./Dropdown.scss";

function Dropdown(props) {
  return (
    <div className="nav-item dropdown" data-html2canvas-ignore="true">
      <button
        className={`dropdown_button nav-link dropdown-toggle btn btn-link shadow-none  ${props.style}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        {props.buttonName}
      </button>
      {props.children}
    </div>
  );
}

function SelectedItem({
  placeHolder,
  selectedIndex,
  items,
  textClassName = "",
}) {
  if (selectedIndex === -1 && placeHolder) {
    return (
      <span
        className={`dropdown__text dropdown__text--default ${textClassName}`}
      >
        {placeHolder}
      </span>
    );
  }
  const { label } = items[selectedIndex] || {};
  return (
    <span className={`dropdown__text dropdown__text ${textClassName}`}>
      {label}
    </span>
  );
}

function PlainDropdown({
  items,
  onItemSelect,
  className = "",
  textClassName = "",
  selectedId,
  placeHolder,
  dropMenuClassName = "",
  disabled = false,
}) {
  const [selected, setSelected] = useState(placeHolder ? -1 : 0);
  const selectedIndex = selectedId
    ? items.findIndex((item) => item.id === selectedId)
    : selected;

  function onItemClick(index) {
    setSelected(index);
    onItemSelect(items[index].id);
  }

  return (
    <div className="dropdown">
      <button
        type="button"
        className={`dropdown__button ${className}`}
        data-toggle="dropdown"
        data-display="static"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={disabled}
      >
        {" "}
        <SelectedItem
          items={items}
          placeHolder={placeHolder}
          selectedIndex={selectedIndex}
          textClassName={textClassName}
        />
        <i className={`fa fa-angle-down text-primary`}></i>
      </button>
      <div className={`dropdown-menu dropdown__menu ${dropMenuClassName}`}>
        {items.map((item, index) => (
          <DropdownItem
            key={index}
            item={item}
            index={index}
            isFirstItemSelectable={placeHolder ? false : true}
            selectedIndex={selectedIndex}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

function DropdownWithCheckboxes({
  options,
  buttonClassName = "",
  dropMenuClassName = "",
  placeHolder = "",
  handleCheckboxChange = () => {},
  selectedItems = [],
}) {
  const isAnyItemSelected = selectedItems.length > 0;
  const btnTextStyle = isAnyItemSelected ? "text-primary" : "";

  // stopping closing menu
  function handleItemClick(e) {
    e.stopPropagation();
  }

  return (
    <div className="dropdown">
      <button
        className={`dropdown__button btn shadow-none rounded-lg border px-2 ${btnTextStyle} ${buttonClassName}`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        data-display="static"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="text-truncate pr-2">{placeHolder}</span>
        <i className="fa fa-angle-down"></i>
      </button>
      <div className={`dropdown-menu dropdown__menu ${dropMenuClassName}`}>
        {options.map((option) => {
          const { id, label } = option;
          const isChecked = selectedItems.some((item) => item === id);
          return (
            <div
              key={id}
              className="dropdown-item px-0"
              onClick={handleItemClick}
            >
              <CheckBox
                boxStyle="mb-0"
                inputBoxStyle="m-0"
                checkBoxKey={label}
                showLabel={true}
                checked={isChecked}
                onChange={() => handleCheckboxChange(option)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

PlainDropdown.defaultProps = {
  onItemSelect: () => {},
  isFirstItemSelectable: false,
  className: "",
  textClassName: "",
};

function DropdownItem({
  item,
  index,
  selectedIndex,
  onItemClick,
  isFirstItemSelectable,
}) {
  const textClassPostfix =
    index === 0 && !isFirstItemSelectable ? "--default" : "";
  const textClass =
    (selectedIndex !== 0 || isFirstItemSelectable) && index === selectedIndex
      ? "active"
      : "";
  return (
    <button
      className={`dropdown-item dropdown__text${textClassPostfix} ${textClass}`}
      onClick={() => onItemClick(index)}
    >
      {item.label}
    </button>
  );
}

export { PlainDropdown, Dropdown, DropdownWithCheckboxes };
