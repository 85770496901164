export const getCampaignPlanByCityUrl =
  "/api/v3/campaign/:campaignId/plan/city/:cityId";
export const getCampaignPlanByCampaignIdUrl =
  "/api/v3/campaign/:campaignId/plan";
export const updateCampaignPlanUrl = "/api/v3/campaign/:campaignId/plan";
export const createCampaignPlanUrl = "/api/v3/campaign/plan";
export const updateCampaignPlanStatusUrl =
  "/api/v3/campaign/:campaignId/plan/status";
export const removeCampaignPlanFilterUrl =
  "/api/v3/campaign/:campaignId/plan/media";
