import { ActionTypes } from "../../../constants/ActionConstants";

export const planCampaign = (campaignId, selectedCitiesMap, history) => ({
  type: ActionTypes.CampaignPlanner.PLAN_CAMPAIGN,
  payload: { campaignId, selectedCitiesMap, history },
});

export const setTitle = (titleStr) => ({
  type: ActionTypes.CampaignPlanner.SET_CAMPAIGN_TITLE,
  payload: { titleStr },
});

export const setTgCpm = (tgCpmStr) => ({
  type: ActionTypes.CampaignPlanner.SET_TG_CPM,
  payload: { tgCpmStr },
});

export const setGenCpm = (genCpmStr) => ({
  type: ActionTypes.CampaignPlanner.SET_GEN_CPM,
  payload: { genCpmStr },
});

export const restoreCampaignPlan = (campaignId, cityId) => ({
  type: ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN,
  payload: { campaignId, cityId },
});

export const updateCampaignDates = (dateObj) => ({
  type: ActionTypes.CampaignPlanner.CHANGE_DURATION,
  payload: { dateObj },
});

export const getTgSpecificOts = (
  tgId,
  stretchIds = [],
  segmentIds = [],
  mediaIds = []
) => ({
  type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS,
  payload: { tgId, stretchIds, segmentIds, mediaIds },
});

// Dispatch function for TgSpecificOts for all "ROAD-STRETCHES" in "CITY"
export const getTgSpecificOtsForCity = (tgId, cityId) => ({
  type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY,
  payload: { tgId, cityId },
});

// Dispatch function for TgSpecificOts for "ROAD-SEGMENTS"
export const getTgSpecificOtsForSegments = (tgId, segmentIds) => ({
  type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_SEGMENTS,
  payload: { tgId, segmentIds },
});

// clear TgSpecificOts for "ROAD-SEGMENTS"
export const clearTgSpecificOtsForSegments = () => ({
  type: ActionTypes.CampaignPlanner.CLEAR_TG_SPECIFIC_OTS_FOR_SEGMENTS,
});

export const removeSelectedTg = () => ({
  type: ActionTypes.CampaignPlanner.RESET_TG_SPECIFIC_OTS,
});

export const getRegionsData = (cityId, restore = false) => ({
  type: ActionTypes.CampaignPlanner.GET_REGIONS,
  payload: { cityId, restore },
});

export const resetCampaignPlanning = () => ({
  type: ActionTypes.CampaignPlanner.RESET_PLAN_CAMPAIGN,
});

export const selectTgAttribute = (tgAttribute) => ({
  type: ActionTypes.CampaignPlanner.SELECT_TG_ATTRIBUTE,
  payload: { tgAttribute },
});

export const unSelectTgAttribute = (tgAttribute) => ({
  type: ActionTypes.CampaignPlanner.UN_SELECT_TG_ATTRIBUTE,
  payload: { tgAttribute },
});

export const toggleArterialRouteType = (routeTypes) => ({
  type: ActionTypes.CampaignPlanner.TOGGLE_ARTERIAL_ROUTE_TYPE,
  payload: { routeTypes },
});

export const toggleMediaType = (mediaTypes) => ({
  type: ActionTypes.CampaignPlanner.TOGGLE_MEDIA_TYPE,
  payload: { mediaTypes },
});

export const resetRegionsData = () => ({
  type: ActionTypes.CampaignPlanner.RESET_REGIONS_DATA,
});
