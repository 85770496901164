// Constants
import { DefaultPagination } from "../constants/GeneralConstants";

//urls
import {
  archiveRoadStretchUrl,
  createRoadStretchUrl,
  getRoadStretchesByIdsUrl,
  getRoadStretchesOfOrgUrl,
  restoreRoadStretchUrl,
  getRoadStretchInfoByIdUrl,
  roadStretchPriceRefreshUrl,
  updateRoadStretchMetaDataUrl,
  getRoadStretchesByCityIdUrl,
} from "../urls/OrgRoadStretchURL";

import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function getRoadStretchesData(
  cityId,
  isArchived,
  arterialRoutes = [],
  mediaTypes = [],
  subZones = []
) {
  // Here, If isArchived == true ==> Meaning we will only get archived RoadStretches
  // If isArchived == false ==> Meaning we will only get  unarchived RoadStretches
  // If isArchived == "" ==> Meaning we will get all RoadStretches
  // (Here,isArchived == "" param is skipped by serializeQueryParams in sendGetRequest,so we will get all RoadStretches )

  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    _ar: isArchived,
    _city: cityId,
    pn: DefaultPagination.pageNumber,
    ps: DefaultPagination.pageSize,
  };

  const arterialRouteLength = arterialRoutes.length;
  const mediaTypeLength = mediaTypes.length;
  const subZoneLength = subZones.length;

  if (arterialRouteLength) {
    params._type = arterialRoutes;
  }

  if (mediaTypeLength) {
    params._media_type = mediaTypes;
  }

  if (subZoneLength) {
    params._zone = subZones;
  }

  return sendGetRequest(
    getRoadStretchesByCityIdUrl,
    params,
    true,
    headerParams
  );
}

export async function getRoadStretchesByIds(stretchIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const roadStretchIds = stretchIds.join();

  const params = {
    ids: roadStretchIds,
  };

  return sendGetRequest(getRoadStretchesByIdsUrl, params, true, headerParams);
}

export async function getRoadStretchesOfOrg(
  isArchived,
  cityId,
  keyWord,
  pageNumber,
  pageSize
) {
  // Here, If isArchived == true ==> Meaning we will only get archived RoadStretches
  // If isArchived == false ==> Meaning we will only get  unarchived RoadStretches
  // If isArchived == "" ==> Meaning we will get all RoadStretches
  // (Here,isArchived == "" param is skipped by serializeQueryParams in sendGetRequest,so we will get all RoadStretches )
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    _ar: isArchived,
  };

  if (keyWord) {
    params._kw = keyWord;
  }

  if (cityId) {
    params._city = cityId;
  }

  return sendGetRequest(getRoadStretchesOfOrgUrl, params);
}

export async function archiveRoadStretch(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = archiveRoadStretchUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function restoreRoadStretch(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreRoadStretchUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function createRoadStretch(stretchBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = createRoadStretchUrl;

  return sendCreateOrUpdateRequest(
    url,
    stretchBean,
    true,
    "POST",
    headerParams
  );
}

export async function getRoadStretchInfoByIdFn(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getRoadStretchInfoByIdUrl.replace(":stretchId", stretchId);

  return sendGetRequest(url, {}, true, headerParams);
}

// Price refresh for stretches
export async function roadStretchPriceRefreshFn(stretchId) {
  const url = roadStretchPriceRefreshUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}

export async function updateRoadStretchMetaDataFn(roadStretchBean, stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    updateRoadStretchMetaDataUrl.replace(":stretchId", stretchId),
    roadStretchBean,
    true,
    "PUT",
    headerParams
  );
}
