export const getPoiNames = "/api/v3/defs/poi_type/search";

//for displaying poi's on map view
export const getPoisOnMap =
  "/api/v1/poi/region/:cityId/type/:poiType?geoJson=false";

// Poi Hierarchy Urls..
// export const getPoiHierarchyUrl = "/api/v3/defs/brand/tree/poi_type";
export const getV2PoiHierarchyUrl = "/api/v1/poi/names";
export const getPoiHierarchyUrl = "/api/v3/defs/brand/tree/sector";

export const getV2BrandInfo =
  "/api/v1/poi/region/:cityId/name/:brandId?geoJson=false";
export const getV2SectorInfo =
  "/api/v1/poi/region/:cityId/type/:sectorId?geoJson=false";
