import { Marker, Polyline, Popup, Tooltip } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

// Actions
import {
  selectMediaType,
  selectPoi,
  selectRoadStretchType,
  selectTargetGroup,
  unselectMediaType,
  unselectPoi,
  unselectRoadStretchType,
  unselectTargetGroup,
} from "../../../actions/map-view/MapViewActions";
import { getMapState } from "../../../actions/map-state/MapStateActions";
import { getRegionDataByCity } from "../../../../actions/regions/RegionActions";

// Constants and Utils
import {
  useSetMediaTypes,
  useSetPoiBrands,
  useSetRoadStretches,
  useSetTgSelected,
} from "../../../utils/HooksUtil";
import { useGetTgHeatMap } from "../../../../mavin/utils/hooks/HooksUtil";
import {
  constructMediaMarker,
  constructPoiMarkers,
  MediaMarkers,
  NoMapViewFilterAppliedMessage,
  RoadStretchLines,
  updateSelectedFilterMap,
} from "./MapViewUtil";
import {
  SelectedRouteStyle,
  UnSelectedRouteStyle,
} from "../../../../constants/CssConstants";
import { getMergedCityId } from "../../../../pages/map-view/CityInfoTempFixUtil";
import { MapZoom } from "../../../../constants/GeneralConstants";
import { getMapViewData } from "./MapViewUtils";
import { MapViewSelectedFilterKeys } from "./MapViewConstants";
import { constructZoneChipsData } from "../FiltersAndSelectionChipsCard";

// Components
import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";
import CheckBox from "../../../../components/check-box/CheckBox";
import LLMap from "../../../../components/map/leaflet-map/LLMap";
import MapIcon from "../../../../components/map/map-icon/MapIcon";
import TargetGroupHeatmap from "../../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import TrafficFlowArrow from "../../../../components/map/traffic-flow-arrow/TrafficFlowArrow";

// Css
import "./MapView.scss";

/**
 * Selected Point of Interest Markers..
 * @returns
 */
function PoiMarkers() {
  const brandDataMarkers = useSelector((state) =>
    Object.keys(state.proohMapView.poiSelectionMap).reduce((acc, eachPoiId) => {
      if (!state.proohMapView.poiSelectionMap[eachPoiId]) {
        return acc;
      }
      const eachBrandDataArray = state.proohMapView.poiToBrandData[eachPoiId];
      eachBrandDataArray.forEach((eachBrandData) => {
        acc = acc.concat(constructPoiMarkers(eachBrandData));
      });
      return acc;
    }, [])
  );

  if (brandDataMarkers.length <= 0) {
    return null;
  }

  return (
    <>
      {brandDataMarkers.map((marker, i) => {
        const icon = new MapIcon({ type: marker.type });
        return (
          <Marker key={i} position={marker.position} icon={icon}>
            <Popup>
              <b>{marker.markerText}</b>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
}

// Media Markers
function MediaMarker({
  media,
  mediaTypeSelection,
  roadStretchOts,
  roadStretch,
}) {
  const { type } = media;
  if (!mediaTypeSelection[type]) {
    return null;
  }

  const { roadStretchName, mediaInfo, impressionsInfo } = constructMediaMarker(
    media,
    roadStretchOts,
    roadStretch
  );

  const totalOts = impressionsInfo.targetOts
    ? impressionsInfo.targetOts + impressionsInfo.genericOts
    : impressionsInfo.genericOts;

  return (
    <Marker key={media.id} position={mediaInfo.position} icon={mediaInfo.icon}>
      <Tooltip
        permanent={true}
        direction="bottom"
      >{`Est Imp: ${totalOts}`}</Tooltip>

      <Popup>
        <div>
          {/* Stretch Info*/}
          <div>
            <b>{"Stretch"}</b>
            <p className="mt-1 mb-2">{roadStretchName}</p>
          </div>

          {/* Media Image And Title*/}
          <div>
            <b>{"Media"}</b>
            <div className="pr-2">{mediaInfo.image}</div>
          </div>

          <div className="mt-2">
            <b>{"Title"}</b>
            <p className="my-1">{mediaInfo.title}</p>
          </div>

          <div className="mt-2">
            <b>{"Type"}</b>
            <p className="my-1">{type}</p>
          </div>

          {/* Media dimensions */}
          <div className="mt-2">
            <b>{"Dimensions"}</b>
            <p className="my-1">{mediaInfo.dimensions}</p>
            <p className="my-1">{mediaInfo.region}</p>
          </div>

          {/* Media Duration */}
          <div className="mt-2">
            <b>{"Duration"}</b>
            <p className="my-1">{mediaInfo.durationString}</p>
          </div>

          {/* Est Impressions */}
          <div className="mt-2">
            <b>{"Est Impressions"}</b>
            {impressionsInfo.targetOts > 0 && (
              <p className="my-1">{`Target Group: ${impressionsInfo.targetOts}`}</p>
            )}
            <p className="my-1">{`Generic: ${impressionsInfo.genericOts}`}</p>
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

// Road Stretches
function StretchLines({ roadStretchOtsMap, roadStretchMap }) {
  const stretchIds = useSelector((state) =>
    Object.keys(state.proohMapView.roadStretchSelection).reduce(
      (acc, eachSelectionType) => {
        if (!state.proohMapView.roadStretchSelection[eachSelectionType]) {
          return acc;
        }
        acc = acc.concat(
          state.proohMapView.roadStretchIdsMap[eachSelectionType]
        );
        return acc;
      },
      []
    )
  );
  return (
    <>
      {stretchIds.map((eachStretchId) => (
        <Fragment key={eachStretchId}>
          <StretchLine
            eachStretch={roadStretchMap[eachStretchId]}
            isSelected={roadStretchOtsMap[eachStretchId] ? true : false}
          />
          {/* Direction of Traffic flow */}
          <TrafficFlowArrow trace={roadStretchMap[eachStretchId].trace} />
        </Fragment>
      ))}
    </>
  );
}

function StretchLine({ eachStretch, isSelected }) {
  const style = isSelected ? SelectedRouteStyle : UnSelectedRouteStyle;
  return (
    <Polyline pathOptions={style} positions={eachStretch.trace}></Polyline>
  );
}

/**
 * Target Group Heat Map
 * @returns
 */
function TgHeatMap() {
  // all poiTypeLayer points combined array ==> TG HeatMap Data
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);
  const selectedTgId =
    useSelector((state) => state.orgTargetGroup.tgInfo?.targetGroup?.id) || "";
  const isTgSelected =
    useSelector((state) => state.proohMapView.targetGroups[selectedTgId]) ||
    false;

  if (allPoiPointsArr.length < 1 || !isTgSelected) {
    return <TargetGroupHeatmap allPoiPointsArr={[]} hmkey="un-select" />;
  }

  return (
    <TargetGroupHeatmap
      hmkey={selectedTgId}
      allPoiPointsArr={allPoiPointsArr}
    />
  );
}

function ZonePanel({
  zones = [],
  selectedFiltersMap = {},
  setSelectedFiltersMap = () => {},
}) {
  const isFilterExist = zones.length > 0;
  const { selectedSubZoneIds = [] } = selectedFiltersMap || {};
  const constructedZones = constructZoneChipsData(zones);

  // function
  function handleCheckbox(e, subZoneId) {
    updateSelectedFilterMap(
      e,
      subZoneId,
      [MapViewSelectedFilterKeys.selectedSubZoneIds],
      selectedFiltersMap,
      setSelectedFiltersMap
    );
  }

  return (
    <CardCollapsible id="zone" header="Zone">
      {!isFilterExist && <NoMapViewFilterAppliedMessage />}
      {isFilterExist &&
        constructedZones.map((eachSubZone) => {
          const { id, label } = eachSubZone || {};
          return (
            <CheckBox
              key={id}
              checkBoxKey={label}
              boxStyle="mb-0 d-flex align-items-center"
              inputBoxStyle="mt-0"
              labelStyle="text-truncate"
              checked={selectedSubZoneIds.includes(id)}
              onChange={(e) => handleCheckbox(e, id)}
            />
          );
        })}
    </CardCollapsible>
  );
}

function MediaTypePanel({
  mediaTypes = [],
  selectedFiltersMap = {},
  setSelectedFiltersMap = () => {},
}) {
  const isFilterExist = mediaTypes.length > 0;
  const { selectedMediaTypes = [] } = selectedFiltersMap || {};

  // function
  function handleCheckbox(e, mediaType) {
    updateSelectedFilterMap(
      e,
      mediaType,
      [MapViewSelectedFilterKeys.selectedMediaTypes],
      selectedFiltersMap,
      setSelectedFiltersMap
    );
  }

  return (
    <CardCollapsible id="MediaType" header="Media Type">
      {!isFilterExist && <NoMapViewFilterAppliedMessage />}
      {isFilterExist &&
        mediaTypes.map((mediaType, i) => (
          <CheckBox
            key={i}
            checkBoxKey={mediaType}
            boxStyle="mb-0 d-flex align-items-center"
            inputBoxStyle="mt-0"
            labelStyle="text-truncate"
            checked={selectedMediaTypes.includes(mediaType)}
            onChange={(e) => handleCheckbox(e, mediaType)}
          />
        ))}
    </CardCollapsible>
  );
}

function ArterialRoutePanel({
  routeTypes = [],
  selectedFiltersMap = {},
  setSelectedFiltersMap = () => {},
}) {
  const isFilterExist = routeTypes.length > 0;
  const { selectedRouteTypes = [] } = selectedFiltersMap || {};

  // function
  function handleCheckbox(e, routeType) {
    updateSelectedFilterMap(
      e,
      routeType,
      [MapViewSelectedFilterKeys.selectedRouteTypes],
      selectedFiltersMap,
      setSelectedFiltersMap
    );
  }

  return (
    <CardCollapsible id="ArterialRoute" header="Arterial Route">
      {!isFilterExist && <NoMapViewFilterAppliedMessage />}
      {isFilterExist &&
        routeTypes.map((routeType, i) => (
          <CheckBox
            key={i}
            checkBoxKey={routeType}
            boxStyle="mb-0 d-flex align-items-center"
            inputBoxStyle="mt-0"
            labelStyle="text-truncate"
            checked={selectedRouteTypes.includes(routeType)}
            onChange={(e) => handleCheckbox(e, routeType)}
          />
        ))}
    </CardCollapsible>
  );
}

function TouchPointsPanel() {
  const touchPointsData = { Malls: false, Office: true, "Cyber City": true };
  return (
    <CardCollapsible id="TouchPoints" header="Touch Points">
      {Object.keys(touchPointsData).map((touchPoint) => (
        <CheckBox
          key={touchPoint}
          checkBoxKey={touchPoint}
          onChange={() => {}}
        />
      ))}
    </CardCollapsible>
  );
}

function MediaSitePanel() {
  const dispatch = useDispatch();

  const mediaTypesCountMap = useSelector(
    (state) => state.proohMapView.mediaTypesCountMap
  );

  const mediaTypeSelection = useSelector(
    (state) => state.proohMapView.mediaTypeSelection
  );

  function onSelectAction(e, selectionType) {
    const selectionTypeValue = selectionType.split("(")[0];
    const checked = e.target.checked;
    checked
      ? dispatch(selectMediaType(selectionTypeValue))
      : dispatch(unselectMediaType(selectionTypeValue));
  }

  return (
    <CardCollapsible id="media-sites" header={"Media Sites"}>
      {Object.keys(mediaTypeSelection).length > 0 &&
        Object.keys(mediaTypeSelection).map((eachMediaType) => (
          <CheckBox
            key={eachMediaType}
            checked={mediaTypeSelection[eachMediaType] === true}
            checkBoxKey={`${eachMediaType}(${mediaTypesCountMap[eachMediaType]})`}
            onChange={onSelectAction}
          />
        ))}
    </CardCollapsible>
  );
}

function RoadStretchPanel() {
  const dispatch = useDispatch();
  const roadStretchSelectOptions = useSelector(
    (state) => state.proohMapView.roadStretchSelection
  );
  const roadStretchIdsMap = useSelector(
    (state) => state.proohMapView.roadStretchIdsMap
  );

  function onSelectAction(e, selectionType) {
    const selectionTypeValue = selectionType.split("(")[0];
    const checked = e.target.checked;
    checked
      ? dispatch(selectRoadStretchType(selectionTypeValue))
      : dispatch(unselectRoadStretchType(selectionTypeValue));
  }

  return (
    <CardCollapsible id="road-stretches" header={"Road Stretches"}>
      {Object.keys(roadStretchSelectOptions).length > 0 &&
        Object.keys(roadStretchSelectOptions).map((eachSelectOption) => (
          <CheckBox
            key={eachSelectOption}
            checked={roadStretchSelectOptions[eachSelectOption] === true}
            checkBoxKey={`${eachSelectOption}(${roadStretchIdsMap[eachSelectOption].length})`}
            onChange={onSelectAction}
          />
        ))}
    </CardCollapsible>
  );
}

function PoiPanel() {
  const dispatch = useDispatch();
  const poiSelectionMap = useSelector(
    (state) => state.proohMapView.poiSelectionMap
  );
  const poiToBrandData = useSelector(
    (state) => state.proohMapView.poiToBrandData
  );

  function constructDisplayValue(poiId) {
    const count = poiToBrandData[poiId].reduce((acc, eachArray) => {
      acc = acc + eachArray.length;
      return acc;
    }, 0);
    return `${poiId}(${count})`;
  }

  function onSelectAction(e, selectionType) {
    const selectionTypeValue = selectionType.split("(")[0];
    const checked = e.target.checked;
    checked
      ? dispatch(selectPoi(selectionTypeValue))
      : dispatch(unselectPoi(selectionTypeValue));
  }

  if (Object.keys(poiToBrandData).length === 0) {
    return null;
  }

  return (
    <CardCollapsible id="poi" header={"Point of interest"}>
      {Object.keys(poiSelectionMap).length > 0 &&
        Object.keys(poiSelectionMap).map((poiId) => (
          <CheckBox
            key={poiId}
            boxStyle={"d-flex align-items-center"}
            inputBoxStyle={"mt-0"}
            checked={poiSelectionMap[poiId] === true}
            checkBoxKey={constructDisplayValue(poiId)}
            onChange={onSelectAction}
          />
        ))}
    </CardCollapsible>
  );
}

function TargetGroupPanel() {
  const dispatch = useDispatch();
  const targetGroup =
    useSelector((state) => state.orgTargetGroup.tgInfo?.targetGroup) || {};
  const { id: tgId, name: tgName } = targetGroup;

  const isTgSelected =
    useSelector((state) => state.proohMapView.targetGroups[tgId]) || false;

  if (!tgName) {
    return null;
  }

  function onSelectFunction(e) {
    const checked = e.target.checked;
    checked
      ? dispatch(selectTargetGroup(tgId))
      : dispatch(unselectTargetGroup(tgId));
  }

  return (
    <CardCollapsible id="tg" header={"Target Group"}>
      <CheckBox
        checkBoxKey={tgName}
        onChange={onSelectFunction}
        checked={isTgSelected}
      />
    </CardCollapsible>
  );
}

/**
 * MapView
 */
export default function MapView({ campaignPlan = {}, roadStretchMap = {} }) {
  const dispatch = useDispatch();
  const { cityId, campaignId } = useParams();

  // State
  const [selectedFiltersMap, setSelectedFiltersMap] = useState({
    [MapViewSelectedFilterKeys.selectedSubZoneIds]: [],
    [MapViewSelectedFilterKeys.selectedRouteTypes]: [],
    [MapViewSelectedFilterKeys.selectedMediaTypes]: [],
  });

  // Campaign Medias
  const campaignMedias = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // Media Types from reducer
  const mediaTypeSelection = useSelector(
    (state) => state.proohMapView.mediaTypeSelection
  );

  // mapState
  const mapState = useSelector((state) => state.proohMapState.mapState);
  const { poiBrandIds = {} } = mapState;

  // Selected Stretches in Planning
  const {
    roadStretchOtsMap = {},
    targetGroupId,
    zones = [],
    mediaTypes = [],
    routeTypes = [],
  } = campaignPlan || {};

  // Info to get the tgHeatMap
  const bbox = useSelector((state) => state.region.regionData?.bbox) || "";
  useSelector((state) => state.campaignPlanning.regionsData.bbox) || "";
  const resultLayers =
    useSelector(
      (state) => state.orgTargetGroup.tgInfo?.targetGroup?.resultLayers
    ) || [];
  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);

  useEffect(() => {
    dispatch(getMapState(campaignId, cityId));
    dispatch(getRegionDataByCity(cityId));
  }, [dispatch, campaignId, cityId]);

  // useSetMediaTypes(campaignMedias);
  // useSetPoiBrands(poiBrandIds, getMergedCityId(cityId));
  // useSetRoadStretches(roadStretchMap, roadStretchOtsMap);
  // useGetTgHeatMap(resPoiLayers, bbox);
  // useSetTgSelected(targetGroupId);

  // useEffect to updated "appliedFilters" to "selectedFiltersMap" for mapView
  useEffect(() => {
    setSelectedFiltersMap({
      [MapViewSelectedFilterKeys.selectedSubZoneIds]: zones.map(
        (eachSubZone) => eachSubZone.zoneId
      ),
      [MapViewSelectedFilterKeys.selectedRouteTypes]: routeTypes,
      [MapViewSelectedFilterKeys.selectedMediaTypes]: mediaTypes,
    });
  }, [JSON.stringify(campaignPlan)]);

  // no data case
  if (
    campaignMedias.length < 1 ||
    !campaignPlan ||
    Object.keys(roadStretchMap).length < 1
  ) {
    return null;
  }

  // Map Coordinates
  const center = Object.values(roadStretchMap)[0].trace[0];

  // Filtered Media & Stretches after Show/Hide From Map
  const mapViewData = getMapViewData(
    selectedFiltersMap,
    campaignMedias,
    campaignPlan,
    roadStretchMap
  );
  const { filteredStretchesMap, filteredMediasMap } = mapViewData || {};

  return (
    <div className="mt-5">
      <h4>Map View</h4>
      <div className="cont-map-view bg-alt">
        {/* Legends for mapView */}
        <div className="map-controls-cont overflow-auto">
          <h5 className="header">Map Display Settings</h5>
          <ZonePanel
            zones={zones}
            selectedFiltersMap={selectedFiltersMap}
            setSelectedFiltersMap={setSelectedFiltersMap}
          />
          <MediaTypePanel
            mediaTypes={mediaTypes}
            selectedFiltersMap={selectedFiltersMap}
            setSelectedFiltersMap={setSelectedFiltersMap}
          />
          <ArterialRoutePanel
            routeTypes={routeTypes}
            selectedFiltersMap={selectedFiltersMap}
            setSelectedFiltersMap={setSelectedFiltersMap}
          />
          {/* uncomment panel when any of below needed */}
          {/* <MediaSitePanel /> */}
          {/* <TargetGroupPanel /> */}
          {/* <PoiPanel /> */}
          {/* <TouchPointsPanel /> */}
          {/* <RoadStretchPanel /> */}
        </div>

        {/* MapView */}
        <LLMap
          center={[center[0], center[1]]}
          zoom={MapZoom.zoomLevel11}
          scrollWheelZoom={false}
        >
          {/* Stretches and Media */}
          <RoadStretchLines filteredStretchesMap={filteredStretchesMap} />
          <MediaMarkers filteredMediasMap={filteredMediasMap} />

          {/* {campaignMedias.map((eachMedia) => (
            <MediaMarker
              key={eachMedia.id}
              media={eachMedia}
              mediaTypeSelection={mediaTypeSelection}
              roadStretchOts={roadStretchOtsMap[eachMedia.roadStretchId]}
              roadStretch={roadStretchMap[eachMedia.roadStretchId]}
            />
          ))} */}
          {/* {Object.keys(roadStretchMap) && (
            <StretchLines
              roadStretchMap={roadStretchMap}
              roadStretchOtsMap={roadStretchOtsMap}
            />
          )} */}

          {/* TG & POI */}
          {/* <TgHeatMap />
          <PoiMarkers /> */}
        </LLMap>
      </div>
    </div>
  );
}
