import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";
import { getBuyersList } from "../../../actions/buyer/BuyerActions";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import {
  getCitySpecificMediaList,
  getSelectedStretchesAndMediaCount,
  getSitesTotalImpressionsAndCost,
  getStaticAndDoohSitesCount,
} from "../media-selection/MediaContainerUtil";
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { getStretchesTotalOtsSplit } from "../../utils/campaignPlanningUtil";

// Components
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import { HeaderSection } from "./HeaderSection";
import CampaignConfirmationForm from "./CampaignConfirmationForm";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import FiltersAndSelectionChipsCard from "../components/FiltersAndSelectionChipsCard";
import SelectionOverviewCard from "../components/SelectionOverviewCard";

// Page Components
function CityOverview({
  campaign,
  cityPlan,
  campaignMedias = [],
  roadStretchDetailsMap = {},
}) {
  const { cityId } = useParams();

  // campaign data
  const { infoByCity = {} } = campaign || {};
  const selectedCity = infoByCity[cityId] || {};
  const { mediaCount = 0 } = selectedCity || {};
  // city specific medias
  const citySpecificMediaList = getCitySpecificMediaList(
    campaignMedias,
    cityId
  );
  // selected stretch data and total mediaCount on stretch
  const { totalStretchesCount } = getSelectedStretchesAndMediaCount(
    cityPlan,
    roadStretchDetailsMap
  );
  const { static: staticSitesCount = 0, DOOH: doohSitesCount = 0 } =
    getStaticAndDoohSitesCount(citySpecificMediaList);

  // sites card data
  const sitesCardData = [
    {
      title: "Stretches",
      value: totalStretchesCount,
    },
    {
      title: "Selected",
      value: mediaCount,
    },
    {
      title: "Static",
      value: staticSitesCount,
    },
    {
      title: "DOOH",
      value: doohSitesCount,
    },
  ];

  // total impressions and cost
  const {
    // genericImpressions = 0,
    // tgImpressions = 0,
    totalEstCost = 0,
  } = getSitesTotalImpressionsAndCost(citySpecificMediaList);

  // city plan data
  const {
    roadStretchOtsMap = {},
    startTimestamp,
    endTimestamp,
  } = cityPlan || {};
  const cityPlanDuration = getDifferenceInDays(startTimestamp, endTimestamp);

  // total ots values
  const { ots = 0, targetOts = 0 } = getStretchesTotalOtsSplit(
    roadStretchOtsMap,
    cityPlanDuration
  );

  // ots card data
  const otsCardData = [
    {
      title: "Total Impressions",
      value: ots,
    },
    {
      title: "TG Impressions",
      value: targetOts,
    },
    {
      title: "Estimated Cost",
      value: totalEstCost,
    },
  ];
  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <h5>Campaign overview</h5>
          <div className="row">
            <div className="col-5">
              <SelectionOverviewCard data={sitesCardData} />
            </div>
            <div className="col-7">
              <SelectionOverviewCard data={otsCardData} />
            </div>
          </div>
        </div>

        {/* filters card */}
        <FiltersAndSelectionChipsCard campaign={campaign} cityPlan={cityPlan} />
      </div>
    </>
  );
}

/**
 * Page
 */
function CampaignReviewPage() {
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);

  const { targetGroupId } = campaignPlan;

  // tg-info
  // const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // stretch details map (overall stretch data including all medias)
  const roadStretchDetailsMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // Campaign confirm popup
  const openCampaignConfirmModal = useSelector(
    (state) => state.campaignConfirmModal.openModal
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuyersList());
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesByCityId(cityId, ""));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/review`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/review`,
  };

  const citySelected = { cityId };

  return (
    <>
      {/* <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      /> */}

      <div className="content-wrapper">
        <HeaderSection campaign={campaign} cityId={cityId} />

        <div className="page-content">
          {/* <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          /> 
          <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={true}
          /> */}

          <CityOverview
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedias={campaignMedia}
            roadStretchDetailsMap={roadStretchDetailsMap}
          />

          {/* Map View */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchDetailsMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchDetailsMap}
          />
        </div>
      </div>

      {/* MODALS */}
      {openCampaignConfirmModal && (
        <CampaignConfirmationForm campaign={campaign} />
      )}
    </>
  );
}

export default CampaignReviewPage;
