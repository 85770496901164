export const RouteTypes = ["Ring Road", "Outer Ring Road"];

export const MediaTypes = ["Road Facing", "LHS", "RHS"];

export const TouchPointsInputItems = [
  { label: "Malls", id: "malls" },
  { label: "Office", id: "office" },
  { label: "Cyber City", id: "cyberCity" },
  { label: "One Horizon", id: "oneHorizon" },
  { label: "Promenade", id: "promenade" },
];

export const TargetGroupData = [
  {
    id: "gender",
    name: "Gender",
    attributes: [
      {
        id: "male",
        name: "Male",
      },
      {
        id: "female",
        name: "Female",
      },
    ],
  },
  {
    id: "ageGroup",
    name: "Age Group",
    attributes: [
      {
        id: "18-25",
        name: "18-25",
      },
      {
        id: "26-35",
        name: "26-35",
      },
      {
        id: "36-45",
        name: "36-45",
      },
      {
        id: "45-Above",
        name: "45-Above",
      },
    ],
  },
  {
    id: "incomeGroup",
    name: "Income Group",
    attributes: [
      {
        id: "SEC-A",
        name: "SEC A",
      },
      {
        id: "SEC-B",
        name: "SEC B",
      },
      {
        id: "SEC-C",
        name: "SEC C",
      },
    ],
  },
  {
    id: "behavioral",
    name: "Behavioral",
    attributes: [],
  },
];

// Dummy Poi Layers
export const DummyPoiLayers = [
  "automobile",
  "bar",
  "school",
  "beauty",
  "books",
  "building",
  "college",
  "jeweller",
  "medicine",
  "nursery",
  "residential",
  "shopping_centre",
  "supermarket",
  "zoo",
];
