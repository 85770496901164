import { GeoData } from "../../constants/action-constants/GeoDataActionConstants";

export const getTgHeatMapData = (
  resPoiLayers,
  bbox,
  setSelectedTab // key to close Tab
) => ({
  type: GeoData.GET_POI_TYPE_LAYER_DATA,
  payload: { resPoiLayers, bbox, setSelectedTab },
});

export const removeTgHeatMapData = () => ({
  type: GeoData.REMOVE_POI_TYPE_LAYER_DATA,
});
