import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // below details stores the "media-selection (true/false)"
  selectedMedia: {},
  selectMediaErr: "",
  unSelectMediaErr: "",

  // below details store the stretchs whose media is selected
  mediaStretchSelectedMap: {},

  // Campaign confirmation
  confirmLoading: false,
  confirmErr: "",
  // TODO: can we use this placeholder to redirect once the
  //       confirmation is success
  confirmSuccess: false,

  // SellerMediaId to campaignMediaId Map
  sellerMediaToCampaignIdMap: {},
  loadingCampaignMedia: false,
  campaignMediaErr: "",

  // MediaId to campaignMedia Map
  mediaIdToCampaignMedia: {},

  // Create campaign media states
  createMediaLoading: false,
  createMediaErr: "",

  // loader for add and remove media button
  selectUnSelectMediaLoading: {},

  // State to store the closing and opening of the mediaDetails
  // on marker click..
  openCloseMediaDetails: {},
};

/**
 *This function constructs 
 mediaStretchSelectedMap, 
 sellerToCampaignMediaMap, 
 selectedMedia and 
 mediaIdToCampaignMedia  states..
 */
function constructGetCampaignMediaSuccessStates(campaignMedia) {
  const sellerMediaToCampaignIdMap = {};
  const mediaStretchSelectedMap = {};
  const selectedMedia = {};
  const mediaIdToCampaignMedia = {};

  campaignMedia.forEach((eachCampaignMedia) => {
    const { mediaId, roadStretchId, id: campaignMediaId } = eachCampaignMedia;
    sellerMediaToCampaignIdMap[mediaId] = campaignMediaId;
    selectedMedia[mediaId] = true;
    mediaIdToCampaignMedia[mediaId] = eachCampaignMedia;

    mediaStretchSelectedMap[roadStretchId] = true;
  });
  return {
    sellerMediaToCampaignIdMap,
    selectedMedia,
    mediaIdToCampaignMedia,
    mediaStretchSelectedMap,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MediaSelection.SELECT_MEDIA: {
      const { campaignMediaBean } = action.payload;
      return {
        ...state,
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [campaignMediaBean.mediaId]: true,
        },
      };
    }

    case ActionTypes.MediaSelection.SELECT_MEDIA_SUCCESS: {
      const { campaignMedia = {}, mediaId } = action.payload || {};
      const { roadStretchId, id: campaignMediaId } = campaignMedia || {};

      return {
        ...state,
        selectedMedia: {
          ...state.selectedMedia,
          [action.payload.mediaId]: true,
        },
        sellerMediaToCampaignIdMap: {
          ...state.sellerMediaToCampaignIdMap,
          [mediaId]: campaignMediaId,
        },
        mediaIdToCampaignMedia: {
          ...state.mediaIdToCampaignMedia,
          [mediaId]: campaignMedia,
        },
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [mediaId]: false,
        },
        mediaStretchSelectedMap: {
          ...state.mediaStretchSelectedMap,
          [roadStretchId]: true,
        },
      };
    }

    case ActionTypes.MediaSelection.SELECT_MEDIA_FAILURE:
      return {
        ...state,
        selectMediaErr: action.payload.error,
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [action.payload.mediaId]: false,
        },
      };

    case ActionTypes.MediaSelection.UN_SELECT_MEDIA: {
      const selectedMedia = { ...state.selectedMedia };
      delete selectedMedia[action.payload.mediaId];
      return {
        ...state,
        selectedMedia,
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [action.payload.mediaId]: true,
        },
      };
    }

    case ActionTypes.MediaSelection.UN_SELECT_MEDIA_SUCCESS: {
      const { mediaId, roadStretchId } = action.payload || {};

      const sellerMediaToCampaignIdMap = {
        ...state.sellerMediaToCampaignIdMap,
      };
      delete sellerMediaToCampaignIdMap[mediaId];

      const mediaIdToCampaignMedia = { ...state.mediaIdToCampaignMedia };
      delete mediaIdToCampaignMedia[mediaId];

      const mediaStretchSelectedMap = {
        ...state.mediaStretchSelectedMap,
      };

      delete mediaStretchSelectedMap[roadStretchId];

      return {
        ...state,
        sellerMediaToCampaignIdMap,
        mediaIdToCampaignMedia,
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [mediaId]: false,
        },
        mediaStretchSelectedMap,
      };
    }

    case ActionTypes.MediaSelection.UN_SELECT_MEDIA_FAILURE:
      return {
        ...state,
        unSelectMediaErr: action.payload.error,
        selectUnSelectMediaLoading: {
          ...state.selectUnSelectMediaLoading,
          [action.payload.mediaId]: false,
        },
      };

    case ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN:
      return {
        ...state,
        confirmLoading: true,
      };

    case ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN_SUCCESS:
      return {
        ...state,
        confirmLoading: false,
        confirmSuccess: true,
      };

    case ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN_FAILURE:
      return {
        ...state,
        confirmLoading: false,
        confirmErr: action.payload,
      };

    case ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA:
      return {
        ...state,
        loadingCampaignMedia: true,
      };

    case ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA_SUCCESS: {
      const {
        sellerMediaToCampaignIdMap,
        selectedMedia,
        mediaIdToCampaignMedia,
        mediaStretchSelectedMap,
      } = constructGetCampaignMediaSuccessStates(action.payload.campaignMedia);
      return {
        ...state,
        sellerMediaToCampaignIdMap,
        selectedMedia,
        mediaIdToCampaignMedia,
        mediaStretchSelectedMap,
        loadingCampaignMedia: false,
      };
    }

    case ActionTypes.CampaignConfirmation.GET_CAMPAIGN_MEDIA_FAILURE: {
      return {
        ...state,
        loadingCampaignMedia: false,
        campaignMediaErr: action.payload,
      };
    }

    // TODO: This action constant has to be changed
    case ActionTypes.CampaignConfirmation.RESET_CAMPAIGN_CONFIRMATION:
      return initialState;

    // Create Campaign media
    //--------------------------------------------------------------------------------
    case ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA:
      return {
        ...state,
        createMediaLoading: true,
      };
    case ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA_SUCCESS:
      return {
        ...state,
        createMediaLoading: false,
      };
    case ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA_FAILURE:
      return {
        ...state,
        createMediaErr: action.payload,
        createMediaLoading: false,
      };

    //media image upload
    //--------------------------------------------------------------------------------
    case ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD:
      return {
        ...state,
        createMediaLoading: true,
      };

    case ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        createMediaLoading: false,
      };

    case ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        createMediaLoading: false,
        createMediaErr: action.payload,
      };

    case ActionTypes.MediaSelection.OPEN_MEDIA_INFO: {
      const { mediaId } = action.payload;
      let isAlreadyOpen = false;
      const updatedInfo = Object.keys(state.openCloseMediaDetails).reduce(
        (acc, eachMediaId) => {
          if (
            mediaId === eachMediaId &&
            state.openCloseMediaDetails[eachMediaId] === true
          ) {
            isAlreadyOpen = true;
            return acc;
          }
          acc[eachMediaId] = false;
          return acc;
        },
        {}
      );

      return {
        ...state,
        openCloseMediaDetails: {
          ...updatedInfo,
          [mediaId]: isAlreadyOpen ? false : true,
        },
      };
    }

    default:
      return state;
  }
};
