import { ActionTypes } from "../../constants/ActionConstants";

export const getCampaignPlan = (campaignId, cityId) => ({
  type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN,
  payload: { campaignId, cityId },
});

export const getCampaignPlanOverview = (campaignId) => ({
  type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW,
  payload: { campaignId },
});

export const changeCampaignPlanStatus = (
  campaignId,
  status,
  history,
  redirectUrl
) => ({
  type: ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS,
  payload: { campaignId, status, history, redirectUrl },
});

export const resetCampaignPlanDetails = () => ({
  type: ActionTypes.CampaignPlanDetails.RESET_CAMPAIGN_PLAN_DETAILS,
});

export const removeCampaignPlanFilter = (campaignId, filtersObj) => ({
  type: ActionTypes.CampaignPlanDetails.REMOVE_CAMPAIGN_PLAN_FILTER,
  payload: { campaignId, filtersObj },
});
