/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import { getPoiTypeLayerDataFn } from "../../apis/GeoDataAPI";
import { GeoData } from "../../constants/action-constants/GeoDataActionConstants";
import poiTypeLayerResponseConverter from "../../utils/PoiTypeLayerResponseConverter";
import { getErrorMessage } from "../../utils/util";

/**
 * @param {*} resPoiLayers => array of multiple string "poiTypeLayers"
 * @param {*} bbox => city bbox
 * @returns the array of all combined poi's points
 */
function* getAllPoiTypeLayersPoints(resPoiLayers, bbox) {
  const allPointsArr = [];
  for (let i = 0; i < resPoiLayers.length; i++) {
    // calling API for each poiLayerType

    const responseObject = yield getPoiTypeLayerDataFn(resPoiLayers[i], bbox);

    // passing "responseObject" from API to converter function
    // to get the only required "points-data"
    const pointArr = yield poiTypeLayerResponseConverter(responseObject);
    allPointsArr.push(...pointArr);
  }
  return allPointsArr;
}

// for getting poi type layer data for specific city
export function* getPoiTypeLayerData(action) {
  const {
    resPoiLayers,
    bbox,
    setSelectedTab, // option key for closing tab
  } = action.payload;

  try {
    // for multiple PoiTypeLayer Ids
    const allPoiPointsArr = yield getAllPoiTypeLayersPoints(resPoiLayers, bbox);

    yield put({
      type: GeoData.GET_POI_TYPE_LAYER_DATA_SUCCESS,
      payload: { allPoiPointsArr },
    });

    // conditionally closing OpenedTab by setting "index => 0"
    if (setSelectedTab) {
      setSelectedTab(0);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: GeoData.GET_POI_TYPE_LAYER_DATA_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(GeoData.GET_POI_TYPE_LAYER_DATA, getPoiTypeLayerData)]);
}
