/* eslint-disable no-case-declarations */
import { SaveMapState } from "../../constants/action-constants/map-view/SaveMapStateActionConstants";

// initial state
const initialState = {
  mapContainer: null,
  center: null,
  cityBBox: "",
  zoom: 11,
  mapHeight: null,
  mapWidth: null,
  cityName: "",

  loading: false,
  savingMapStateErr: "",

  // restore map state details..
  restoreCenter: null,
  restoreZoom: 11,
  restoreLoading: false,
  restoreError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SaveMapState.Save.SAVE_MAP_CONTAINER:
      const { mapContainer, center, zoom } = action.payload;
      return {
        ...state,
        mapContainer: mapContainer,
        mapHeight: mapContainer._container.clientHeight,
        mapWidth: mapContainer._container.clientWidth,
        zoom: zoom,
        center: center,
      };

    case SaveMapState.Save.SAVE_MAP_STATE:
      return {
        ...state,
        loading: true,
      };

    case SaveMapState.Save.SAVE_MAP_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SaveMapState.Save.SAVE_MAP_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        savingMapStateErr: action.payload,
      };

    case SaveMapState.Restore.RESTORE_SAVE_MAP_STATE:
      return {
        ...state,
        restoreLoading: true,
      };

    case SaveMapState.Restore.RESTORE_SAVE_MAP_STATE_SUCCESS:
      const { zoomLevel, centerLatLng, cityBBox, cityName } = action.payload;
      return {
        ...state,
        restoreZoom: zoomLevel ? zoomLevel : state.restoreZoom,
        restoreCenter: centerLatLng.split(","),
        restoreLoading: false,
        cityBBox,
        cityName,
      };

    case SaveMapState.Restore.RESTORE_SAVE_MAP_STATE_FAILURE:
      return {
        ...state,
        restoreLoading: false,
        restoreError: action.payload,
      };

    default:
      return state;
  }
};
