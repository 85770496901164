import { Fragment } from "react";
import { Marker, Polyline, Popup, Tooltip } from "react-leaflet";

// Constants and Utils
import { constructDateString } from "../../../../common-utils/date-utils/DateUtils";
import {
  DATE_FORMATS,
  LIT_STATUS,
} from "../../../../constants/GeneralConstants";
import { formatText } from "../../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../../common-utils/number-utils/NumberUtils";
import { SelectedRouteStyle } from "../../../../constants/CssConstants";

// Components
import MapIcon from "../../../../components/map/map-icon/MapIcon";
import TrafficFlowArrow from "../../../../components/map/traffic-flow-arrow/TrafficFlowArrow";

/**
 *  Function which converts each heatMap point to respective marker
 * @param {*} dataPoints
 * @returns
 */
export function constructPoiMarkers(dataPoints) {
  if (!dataPoints) {
    return [];
  }

  return dataPoints.map((dp) => ({
    id: dp.id,
    type: dp.type,
    markerText: dp.name,
    position: [dp.center.latitude, dp.center.longitude],
  }));
}

// IMAGE
function getPopupMediaImage(media = {}) {
  const imageUrl = media?.fileItems[0]?.thumbnailUrl || "";

  if (!imageUrl) {
    return (
      <div className="text-center">
        <i className="far fa-images fa-4x opacity-25 text-primary"></i>
        <p className="font-italic my-0 sub-text">No Image Available</p>
      </div>
    );
  }

  return (
    <img src={imageUrl} alt="thumbnail-image" className="popup-media-image" />
  );
}

function constructMediaInfo(media = {}) {
  const {
    latitude,
    longitude,
    title,
    type,
    width,
    height,
    litStatus,
    region,
    startTimestamp,
    endTimestamp,
    otsSummary = {},
  } = media || {};
  const { ots, targetOts } = otsSummary || {};

  const position = [latitude, longitude];
  const dimensions = `W: ${width} ft x H: ${height} ft | ${LIT_STATUS[litStatus]}`;
  const image = getPopupMediaImage(media);
  const icon = new MapIcon({ type: "selected-media" });

  const durationString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const formattedOts = ots === 0 ? 0 : formatText(toStringWithRoundUp(ots));
  const formattedTgOts =
    targetOts === 0 ? 0 : formatText(toStringWithRoundUp(targetOts));

  return {
    title,
    position,
    dimensions,
    type,
    image,
    region,
    durationString,
    icon,
    ots: formattedOts,
    targetOts: formattedTgOts,
  };
}

function constructImpressionsInfo(roadStretchOts) {
  const { ots, otsLit, genericOts, genericOtsLit, targetOts, targetOtsLit } =
    roadStretchOts;

  const estGenericOts = genericOtsLit ?? genericOts;
  const estTargetOts = targetOtsLit ?? targetOts;
  const estOts = ots ?? otsLit;
  return { genericOts: estGenericOts ?? estOts, targetOts: estTargetOts ?? 0 };
}

export function constructMediaMarker(
  media,
  roadStretchOts = 0,
  roadStretch = {}
) {
  const { name: roadStretchName } = roadStretch;
  const mediaInfo = constructMediaInfo(media);
  const impressionsInfo = constructImpressionsInfo(roadStretchOts);
  return { roadStretchName, mediaInfo, impressionsInfo };
}

// MapView :: update Selected Filter Map
export function updateSelectedFilterMap(
  event = {},
  keyToAddOrRemove,
  keyToUpdate,
  selectedFiltersMap = {},
  setSelectedFiltersMap = () => {}
) {
  // Status
  const { checked } = event.target || {};

  // existing items
  const alreadySelectedItems =
    Object.keys(selectedFiltersMap).length > 0
      ? selectedFiltersMap[keyToUpdate]
      : [];

  // if not checked then remove
  if (!checked) {
    const keyIndex = alreadySelectedItems.indexOf(keyToAddOrRemove);

    // only splice array when item is found
    if (keyIndex > -1) {
      const clonedAlreadySelectedItems = [...alreadySelectedItems];
      clonedAlreadySelectedItems.splice(keyIndex, 1);

      // update map
      setSelectedFiltersMap({
        ...selectedFiltersMap,
        [keyToUpdate]: clonedAlreadySelectedItems,
      });
    }
    return;
  }

  // append the key
  setSelectedFiltersMap({
    ...selectedFiltersMap,
    [keyToUpdate]: [...alreadySelectedItems, keyToAddOrRemove],
  });
}

// No MapView Filter Applied Message
export function NoMapViewFilterAppliedMessage({
  message = "Not Selected",
  className = "mt-n2",
}) {
  return (
    <p className={`mb-0 font-italic text-black-50 ${className}`}>{message}</p>
  );
}

// Road Stretch Lines
export function RoadStretchLines({ filteredStretchesMap = {} }) {
  const stretchesIds = Object.keys(filteredStretchesMap);
  const isStretchesPresent = stretchesIds.length > 0;

  // if no stretches present
  if (!isStretchesPresent) {
    return null;
  }

  return (
    <>
      {stretchesIds.map((stretchId) => {
        const stretchInfo = filteredStretchesMap[stretchId];
        const { trace = [], name: stretchName } = stretchInfo || {};
        return (
          <Fragment key={stretchId}>
            <Polyline pathOptions={SelectedRouteStyle} positions={trace}>
              <Popup>{stretchName}</Popup>
            </Polyline>
            <TrafficFlowArrow trace={trace} />
          </Fragment>
        );
      })}
    </>
  );
}

// Media Markers
export function MediaMarkers({ filteredMediasMap = {} }) {
  const mediaList = Object.values(filteredMediasMap);

  // if there are no media
  if (mediaList.length === 0) {
    return null;
  }

  return (
    <>
      {mediaList.map((media) => {
        const { id, stretchInfo = {} } = media;
        const { name: stretchName, segmentIds = [] } = stretchInfo || {};

        const mediaInfo = constructMediaInfo(media);
        const {
          position,
          icon,
          ots,
          targetOts,
          image,
          title: mediaName,
          type,
          dimensions,
          durationString,
        } = mediaInfo || {};

        const stretchOrSegmentText =
          segmentIds.length > 0 ? "Stretch" : "Segment";

        return (
          <Marker key={id} position={position} icon={icon}>
            <Tooltip permanent={true} direction="bottom">
              {`Est Imp: ${ots}`}
            </Tooltip>

            <Popup>
              {/* Stretch Details */}
              <b className="mb-0">{stretchOrSegmentText}</b>
              <p className="mt-0 mb-3 text-truncate-2" title={stretchName}>
                {stretchName}
              </p>

              {/* Media Details*/}
              <b>{"Media"}</b>
              <div className="mb-2 rounded-lg overflow-hidden">{image}</div>

              <b>{"Title"}</b>
              <p className="mt-0 mb-2 text-truncate-2" title={mediaName}>
                {mediaName}
              </p>

              <b>{"Type"}</b>
              <p className="mt-0 mb-2">{type}</p>

              <b>{"Dimensions"}</b>
              <p className="mt-0 mb-2">{dimensions}</p>

              <b>{"Duration"}</b>
              <p className="mt-0 mb-2">{durationString}</p>

              <b>{"Est Impressions"}</b>
              <p className="mt-0 mb-0">{`Generic: ${ots}`}</p>
              <p className="mt-0 mb-2">{`Target Group: ${targetOts}`}</p>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
}
