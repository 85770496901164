import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { openLocationOfIntrestForm } from "../../../actions/map-view/LocationOfIntrestActions";

// Utils & constants
import { isDivDisabled } from "../../../../pages/map-view/MapViewUtil";
import { useUrlKeyCheck } from "../../../../mavin/utils/hooks/HooksUtil";
import {
  FormDataTargets,
  UrlKeys,
} from "../../../../constants/GeneralConstants";

// Tabs
import MediaSitesTab from "../../../../pages/map-view/media-sites/MediaSitesTab";
import RoadStretchesTab from "../../../../pages/map-view/road-stretches/RoadStretchesTab";
import PointsOfInterestTab from "../../../../pages/map-view/points-of-interest/PointsOfInterestTab";
import BoundariesTab from "../../../../pages/map-view/boundaries/BoundariesTab";
import DataLayersTab from "../../../../pages/map-view/data-layers/DataLayersTab";
import SecTab from "../../../../pages/map-view/sec/SecTab";

// Constants

const contents = [
  <>
    <i className="fas fa-map-marker-alt my-2 text-primary"></i>
    <p className="pb-0">Media sites</p>
  </>,
  <>
    <i className="far fa-compass my-2 text-primary"></i>
    <p className="pb-0">Points of Interest</p>
  </>,
  <>
    <i className="fas fa-border-all my-2 text-primary"></i>
    <p className="pb-0">Boundaries</p>
  </>,
  <>
    <i className="fas fa-border-none my-2 text-primary"></i>
    <p className="pb-0">SEC</p>
  </>,
  <>
    <i className="fas fa-road my-2 text-primary"></i>
    <p className="pb-0">Road Stretches</p>
  </>,
  <>
    <i className="fas fa-layer-group my-2 text-primary"></i>
    <p className="pb-0">Data Layers</p>
  </>,
];

// Component
// ----------------------------------------------------------------------------

function SelectedContent({ selectedTab, campaignId, cityId, bbox }) {
  switch (selectedTab) {
    case 1:
      return <MediaSitesTab campaignId={campaignId} cityId={cityId} />;
    case 2:
      return <PointsOfInterestTab cityId={cityId} bbox={bbox} />;
    case 3:
      return <BoundariesTab cityId={cityId} />;
    case 4:
      return <SecTab cityId={cityId} bbox={bbox} />;
    case 5:
      return <RoadStretchesTab cityId={cityId} />;
    case 6:
      return <DataLayersTab cityId={cityId} />;
    default:
      return null;
  }
}

function MapTabsSection({ selectedTab, tabOnClick, cityId, campaignId, bbox }) {
  const dispatch = useDispatch();

  const { campaignPlanning } = UrlKeys;

  // if the "plan" key is present in url then its is from campaign planning page
  const isFromCampaignPlanner = useUrlKeyCheck(campaignPlanning);
  return (
    <div className="map-tabs-cont">
      <div className="tab-items">
        <div
          className="tab-item text-primary"
          data-toggle="modal"
          data-target={`#${FormDataTargets.locationOfIntrestForm}`}
          onClick={() => dispatch(openLocationOfIntrestForm())}
        >
          <i className="fas fa-map-marker-alt my-2"></i>
          <p className="pb-0">Location Of Interest</p>
        </div>

        {contents.map((content, index) => (
          <div
            key={index}
            className={`tab-item text-primary ${
              selectedTab === index + 1 ? "active" : ""
            }`}
            onClick={() => tabOnClick(index + 1)}
            disabled={isDivDisabled(index + 1, isFromCampaignPlanner)}
          >
            {content}
          </div>
        ))}
      </div>

      {!!selectedTab && (
        <div className="tab-cont bg-white">
          <SelectedContent
            selectedTab={selectedTab}
            campaignId={campaignId}
            cityId={cityId}
            bbox={bbox}
          />
        </div>
      )}
    </div>
  );
}

export default MapTabsSection;
