import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import { planCampaign } from "../../actions/campaign-planning/CampaignPlanningActions";

// Constants and Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { constructTotalImpAndCost } from "../../utils/campaignPlanningUtil";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Page Components
function FooterActionButtons({ selectedCitiesMap = {} }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const planCampaignLoading = useSelector(
    (state) => state.campaignPlanning.planCampaignLoading
  );

  // Cancel onClick function
  function cancelCampaignFn() {
    // Redirecting to HomePage
    history.push(constructRedirectPath("/home"));

    // to reload the page --> that will automatically clear the "Redux-states"
    history.go(0);
  }

  if (planCampaignLoading) {
    return <Spinner className="mx-3" />;
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary mr-2 px-4 rounded-lg"
        onClick={cancelCampaignFn}
      >
        {"Cancel"}
      </button>
      <button
        type="button"
        className="btn btn-primary px-4 rounded-lg"
        onClick={() =>
          dispatch(planCampaign(campaignId, selectedCitiesMap, history))
        }
      >
        {"Continue"}
      </button>
    </>
  );
}

// Page Section
function PlanningFooterSection({ selectedCitiesMap = {} }) {
  // Selector State
  const tgId = useSelector((state) => state.tgSpecificOts.tgId);

  const tgSpecificOts = useSelector(
    (state) => state.tgSpecificOts.tgSpecificOts
  );

  const selectedStretchesMap = useSelector(
    (state) => state.planningRoadStretches.selectedStretches
  );

  const stretchesCount = Object.keys(selectedStretchesMap).length;
  const genCpm = useSelector((state) => state.campaignPlanning.genCpm);
  const tgCpm = useSelector((state) => state.campaignPlanning.tgCpm);

  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // TODO: can we make this into useMemo hook...
  const { genericImpressions, specificImpressions, specificCost, genericCost } =
    constructTotalImpAndCost(
      selectedStretchesMap,
      tgId && Object.keys(tgSpecificOts).length > 0
        ? tgSpecificOts
        : selectedStretchesMap,
      duration,
      tgCpm,
      genCpm
    );

  const totalEstCost = specificCost ? specificCost + genericCost : genericCost;

  const totalCalculatedOts = specificImpressions
    ? specificImpressions + genericImpressions
    : genericImpressions;

  return (
    <>
      <hr className="divider m-0"></hr>
      <div className="d-flex justify-content-end p-3">
        {/* <div className="px-2">
          <p className="mb-1">STRETCHES ADDED</p>
          <b>{stretchesCount ? stretchesCount : "0"}</b>
        </div>
        <div className="px-2">
          <p className="mb-1">Total Est Imp</p>
          <b>
            {totalCalculatedOts ? toStringWithRoundUp(totalCalculatedOts) : "0"}
          </b>
        </div>

        <div className="px-2">
          <p className="mb-1">Total Est Cost</p>
          <b>
            {"Rs. "}
            {totalEstCost ? toStringWithRoundUp(totalEstCost) : "0"}
          </b>
        </div> */}

        <div className="p-2">
          <FooterActionButtons selectedCitiesMap={selectedCitiesMap} />
        </div>
      </div>
      <hr className="divider m-0"></hr>
    </>
  );
}

export default PlanningFooterSection;
