export const ZonesAndSubZones = {
  GET_ZONES: "GET_ZONES",
  GET_ZONES_SUCCESS: "GET_ZONES_SUCCESS",
  GET_ZONES_FAILURE: "GET_ZONES_FAILURE",

  GET_SUB_ZONES: "GET_SUB_ZONES",
  GET_SUB_ZONES_SUCCESS: "GET_SUB_ZONES_SUCCESS",
  GET_SUB_ZONES_FAILURE: "GET_SUB_ZONES_FAILURE",

  SELECT_SUB_ZONE: "SELECT_SUB_ZONE",
  UN_SELECT_SUB_ZONE: "UN_SELECT_SUB_ZONE",

  GET_FREQUENTLY_OPTED_LOCATIONS: "GET_FREQUENTLY_OPTED_LOCATIONS",
  GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS:
    "GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS",
  GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE:
    "GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE",

  CLEAR_SELECTED_ZONES_AND_SUB_ZONES_MAP:
    "CLEAR_SELECTED_ZONES_AND_SUB_ZONES_MAP",
};
