import { fork } from "redux-saga/effects";

import planningRoadStretches from "./campaign-planning/CampaignPlanningRoadStretchesSaga";
import TgSpecificOtsSaga from "./TgSpecificOtsSaga";
import poiModel from "./campaign-planning/CampaignPlanningPoiModelSaga";
import planningRoadSegments from "./campaign-planning/CampaignPlanningRoadSegmentsSaga";
import campaignPlanning from "./campaign-planning/CampaignPlanningSaga";
import MediaSelectionSaga from "./media-selection/MediaSelectionSaga";
import CampaignConfirmationSaga from "./campaign-confirmation/CampaignConfirmationSaga";
import campaignReviewSaga from "./campaign-review/CampaignReviewSaga";
import CampaignPerformanceSaga from "./campaign-performance/CampaignPerformanceSaga";
import PoiSelectionFormSaga from "./campaign-planning/PoiSelectionFormSaga";
import MapStateSaga from "./map-state/MapStateSaga";
import MapViewSaga from "./map-view/MapViewSaga";

// Zones & SubZones
import ZonesAndSubZonesSaga from "./ZonesAndSubZonesSaga";

export const proohSagas = [
  fork(planningRoadSegments),
  fork(TgSpecificOtsSaga),
  fork(planningRoadStretches),
  fork(poiModel),
  fork(campaignPlanning),
  fork(MediaSelectionSaga),
  fork(CampaignConfirmationSaga),
  fork(campaignReviewSaga),
  fork(CampaignPerformanceSaga),
  fork(PoiSelectionFormSaga),
  fork(MapStateSaga),
  fork(MapViewSaga),

  // Zones & SubZones
  fork(ZonesAndSubZonesSaga),
];
