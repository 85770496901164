import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getRegionsData,
  setTitle,
  updateCampaignDates,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import { resetRoadStretches } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import {
  selectSubZone,
  unSelectSubZone,
} from "../../actions/campaign-planning/ZonesAndSubZonesActions";

// Constants and Utils
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";
import TitleEditor from "../../../components/title-editor/TitleEditor";
import {
  PlainDropdown,
  DropdownWithCheckboxes,
} from "../../../components/dropdown/Dropdown";

// CSS
import "./CampaignPlanningPage.scss";

// Page Functions
function constructFrequentlyOptedLocations(frequentlyOptedLocations = []) {
  const constructedFOL = [];

  frequentlyOptedLocations.forEach((location) => {
    const { zoneId, zoneName = "", percentage } = location || {};
    constructedFOL.push({
      id: zoneId,
      label: `${zoneName}-(${percentage}%)`,
      name: zoneName,
    });
  });

  return constructedFOL;
}

function getInitialDateAndDuration(startTimestamp, endTimestamp) {
  if (!startTimestamp && !endTimestamp) {
    return { startDateObj: "", duration: "" };
  }

  const duration = getDifferenceInDays(startTimestamp, endTimestamp);
  return { startDateObj: new Date(startTimestamp), duration };
}

// Frequently Opted Locations
function FrequentlyOptedLocations({ regionsData = {} }) {
  const dispatch = useDispatch();

  // State
  const [selectedItems, setSelectedItems] = useState([]);

  // data
  const { id: selectedCityId, name } = regionsData || {};

  // Freq Opt Locations
  const frequentlyOptedLocations = useSelector(
    (state) => state.zonesAndSubZones.frequentlyOptedLocations
  );
  const constructedFreqOptLocations = constructFrequentlyOptedLocations(
    frequentlyOptedLocations
  );

  // Selected ZOnes & SubZones Map
  const selectedZonesAndSubZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedZonesAndSubZonesMap
  );

  const cityZonesAndSubZonesData =
    selectedZonesAndSubZonesMap[selectedCityId] || [];

  const selectedSubZonesIds = cityZonesAndSubZonesData.map(
    (subZone) => subZone.subZoneId
  );

  function onOptionSelection(selectedSubZone = {}) {
    const updatedSelectedSubZone = {
      zoneId: selectedCityId,
      zoneName: name,
      subZoneId: selectedSubZone.id,
      subZoneName: selectedSubZone.name,
    };

    if (Object.keys(updatedSelectedSubZone.length > 0)) {
      const isSelectedZone = selectedSubZonesIds.includes(
        updatedSelectedSubZone.subZoneId
      );

      const dispatchFn = isSelectedZone ? unSelectSubZone : selectSubZone;
      dispatch(dispatchFn(updatedSelectedSubZone));
    }
  }

  function handleCheckboxChange(option) {
    const updatedSelections = [...selectedItems];

    if (selectedItems.some((item) => item.id === option.id)) {
      // If already selected, remove it
      const index = updatedSelections.findIndex(
        (item) => item.id === option.id
      );
      updatedSelections.splice(index, 1);
    } else {
      // If not selected, add it
      updatedSelections.push(option);
    }

    setSelectedItems(updatedSelections);
    onOptionSelection(option);
  }

  if (constructedFreqOptLocations.length === 0) {
    return null;
  }

  return (
    <div className="mx-2">
      <p className="mb-0">Freq. Opt. Locations</p>
      <DropdownWithCheckboxes
        options={constructedFreqOptLocations}
        placeHolder="Select Area"
        dropMenuClassName="dropdown-fixed-height min-width-200"
        handleCheckboxChange={handleCheckboxChange}
        selectedItems={selectedSubZonesIds}
      />
    </div>
  );
}

function StartDateAndDurationSection() {
  const dispatch = useDispatch();

  // Selector
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { startTimestamp, endTimestamp } = campaignPlan;
  const { startDateObj, duration } = getInitialDateAndDuration(
    startTimestamp,
    endTimestamp
  );

  const [startDate, setStartDate] = useState(startDateObj);

  // DatePicker Style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "start-date-selector px-2",
  };

  // Functions
  function selectDuration(duration) {
    const date = new Date(startDate);
    const endDate = new Date(date.setDate(date.getDate() + duration - 1));
    dispatch(updateCampaignDates({ startDate, endDate }));
  }

  return (
    <div className="d-flex">
      <div className="mr-3">
        <p className="mb-0">{"Start Date"}</p>
        {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
        <BootstrapDateRangePicker
          isSingleDatePicker={true}
          initialStartDate={startDate}
          onApplyDates={setStartDate}
          minDate={new Date()}
          placeHolder={"Select Date"}
          styleObject={styleObject}
        />
      </div>
      <div>
        <p className="mb-0">{"Duration"}</p>
        <DurationSelector
          onItemSelect={selectDuration}
          startDate={startDate}
          initialDays={duration}
          buttonClassName="px-2"
        />
      </div>
    </div>
  );
}
function SingleCitySelectionSelector() {
  const dispatch = useDispatch();

  const regionsData = useSelector(
    (state) => state.campaignPlanning.regionsData
  );
  const { name: selectedCityName, id: selectedCityId } = regionsData || {};

  // Functions
  function onCitySelect(cityId) {
    dispatch(resetRoadStretches());
    dispatch(getRegionsData(cityId));
  }

  // for now only needed cities
  const citySelectItems = [{ id: "city-delhi-ncr", label: "Delhi NCR" }];

  return (
    <div>
      <p className="mb-0">Select City</p>
      {/* uncomment when modal needed to select city */}
      {/* <button
        type="button"
        className="btn border rounded-lg shadow-none p-2 dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={() => dispatch(openSingleCitySelectionForm())}
      >
        {selectedCityName ? selectedCityName : "Select City"}
      </button> */}

      <PlainDropdown
        items={citySelectItems}
        className="btn shadow-none rounded-lg border px-2"
        placeHolder="Select City"
        selectedId={selectedCityId}
        onItemSelect={onCitySelect}
        dropMenuClassName="dropdown-fixed-height"
      />
    </div>
  );
}

/**
 * Section
 */
function PlanningHeaderSection({ regionsData = {} }) {
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);
  const { title } = campaign || {};

  const campaignPlanTitle = useSelector(
    (state) => state.campaignPlanning.campaignTitle
  );

  const campaignTitle = campaign ? title : campaignPlanTitle;

  return (
    <>
      <div className="d-flex align-items-center m-3">
        <TitleEditor
          title={campaignTitle}
          onTitleChange={(titleStr) => dispatch(setTitle(titleStr))}
          inputBoxClass={"rounded-lg"}
          titleClass={"px-0 mb-0 h3"}
        />
      </div>
      <hr className="my-0"></hr>
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <SingleCitySelectionSelector />

        {/* uncomment when integrating */}
        <FrequentlyOptedLocations regionsData={regionsData} />

        <StartDateAndDurationSection />
      </div>
      <hr className="my-0"></hr>
    </>
  );
}

export default PlanningHeaderSection;
