import "./Chips.scss";

// Single Chip
function Chip({
  item = {},
  customClassName,
  onDeselect = () => {},
  isRemovable = true,
}) {
  const { id, label } = item || {};
  return (
    <div className="position-relative pr-2 mr-2 py-2 mt-1">
      <div
        title={label}
        className={`py-1 px-3 rounded-lg text-truncate chip-max-width ${customClassName}`}
      >
        {label}
      </div>
      {isRemovable && (
        <span
          title="Remove"
          className="rounded-circle deselect"
          onClick={() => onDeselect(id, item)}
        >
          <i className="fas fa-times"></i>
        </span>
      )}
    </div>
  );
}

/**
 * Chips
 * @param {*} selectedItems = [{id: "1", label: "One"}] // must have id and label
 */
export default function Chips({
  selectedItems = [],
  title,
  customClassName = "bg-primary text-white",
  containerClassName = "",
  headerClassName = "",
  showHrTag = false,
  onDeselect = () => {},
  isRemovable = true,
  noDataMessage = "",
}) {
  return (
    <>
      {title && (
        <h5 className={`mb-0 text-truncate ${headerClassName}`} title={title}>
          {title}
        </h5>
      )}

      <div className={`d-flex ${containerClassName}`}>
        {/* no data message */}
        {noDataMessage && (
          <p className="font-italic mb-0 text-muted mt-2">{noDataMessage}</p>
        )}

        {/* chips */}
        {selectedItems.map((item, i) => (
          <Chip
            key={i}
            item={item}
            customClassName={customClassName}
            onDeselect={onDeselect}
            isRemovable={isRemovable}
          />
        ))}
      </div>
      {showHrTag && <hr className="my-2" />}
    </>
  );
}
