import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  clearBuyerUsersList,
  getUsersByBuyerId,
} from "../../../actions/buyer/BuyerActions";
import { updateCampaignAndSaveSettings } from "../../actions/campaign-review/CampaignReviewActions";

// Constants and Utils
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Title
function ModalTitle() {
  return (
    <div className="modal-header bg-alt px-5">
      <h4 className="modal-title">Send confirmation</h4>
    </div>
  );
}

function ModalFooter({
  handleSubmitForm = () => {},
  handleClearForm = () => {},
  disableSendButton,
}) {
  // constants
  const cancelAndSaveButtonDetails = [
    {
      label: "Cancel",
      className: "btn-outline-primary",
      onClick: handleClearForm,
      disabled: false,
    },
    {
      label: "Send",
      className: "btn-primary",
      onClick: handleSubmitForm,
      disabled: disableSendButton,
    },
  ];

  return (
    <div className="modal-footer px-5">
      {cancelAndSaveButtonDetails.map((item, i) => {
        const { label, className, onClick, disabled } = item || {};
        return (
          <button
            key={i}
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`btn ${className} btn-action`}
            data-dismiss="modal"
          >
            {label}
          </button>
        );
      })}
    </div>
  );
}

function CampaignName({ title, setTitle }) {
  return (
    <div className="form-group d-flex">
      <label htmlFor="campaignName" className="col-2 px-0 col-form-label">
        Campaign Name
      </label>
      <div className="col-10 px-0">
        <input
          type="text"
          value={title}
          className="form-control shadow-none rounded-lg"
          onChange={({ target }) => setTitle(target.value)}
          placeholder="Enter campaign name"
          id="campaignName"
        />
      </div>
    </div>
  );
}

function BuyerName({
  buyerName,
  setBuyerName = () => {},
  setBuyerId = () => {},
}) {
  const dispatch = useDispatch();

  // State
  const [suggestedBuyersName, setSuggestedBuyersName] = useState([]);

  // Selector
  const buyersList = useSelector((state) => state.buyer.buyersList);

  // Search buyer From Input Function
  function searchBrandFn({ target }) {
    const inputText = target.value;
    setBuyerName(inputText);
    if (inputText.length <= 1) {
      return setSuggestedBuyersName([]), setBuyerId("");
    }

    const suggestion = buyersList.filter((eachBuyer) =>
      eachBuyer.name.toLowerCase().includes(inputText.toLowerCase())
    );
    setSuggestedBuyersName(suggestion);
  }

  // function to select buyer from suggestions
  function handleBrandSelect(eachBuyer) {
    const { id, name } = eachBuyer || {};
    setSuggestedBuyersName([]);
    setBuyerId(id);
    setBuyerName(name);

    // action to get users by buyer id
    dispatch(getUsersByBuyerId(id));
  }

  return (
    <div className="form-group d-flex">
      <label htmlFor="brand" className="col-2 px-0 col-form-label">
        Brand Name
      </label>
      <div className="col-10 px-0">
        <input
          type="search"
          className="form-control rounded-lg shadow-none"
          placeholder="Search brand"
          id="brand"
          value={buyerName}
          onChange={searchBrandFn}
        />

        {/* Suggested Buyer name*/}
        {suggestedBuyersName.length > 0 && (
          <div className="border rounded-lg mt-1 bg-alt">
            {/* we are showing only five suggestions which matches search input */}
            {suggestedBuyersName.slice(0, 5).map((eachBuyer, i) => (
              <li
                key={i}
                className="dropdown-item cursor-pointer"
                onClick={() => handleBrandSelect(eachBuyer)}
              >
                {eachBuyer.name}
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function UserSelectionSection({
  user = {},
  handleUsers = () => {},
  saveSettings = {},
}) {
  const { id, emailId, firstName } = user || {};
  const saveSettingsKeys = Object.keys(saveSettings);
  const isUserSelected = saveSettingsKeys.includes(id);

  return (
    <div className="form-check form-check-inline">
      <input
        type="checkbox"
        value={id}
        className="form-check-input"
        checked={isUserSelected}
        onChange={handleUsers}
      />
      <p className="m-0 pl-2">
        <span>{firstName}</span>
        <span className="pl-1">({emailId})</span>
      </p>
    </div>
  );
}

function Contacts({ saveSettings, setSaveSettings }) {
  const { campaignId } = useParams();

  // Selector
  const usersList = useSelector((state) => state.buyer.usersList);
  const usersListLoading = useSelector((state) => state.buyer.usersListLoading);

  // handle user selection
  function handleUsers(event) {
    const { target = {}, currentTarget = {} } = event || {};
    const { value: userId } = target || {};

    // to select user
    if (currentTarget.checked) {
      const shareSettingObj = {
        entityType: "CAMPAIGN",
        entityId: campaignId,
        sharedWith: userId,
        accessLevel: "READ",
        notes: "Please review the Campaign and Approve.",
      };
      setSaveSettings({ ...saveSettings, [userId]: shareSettingObj });
    } else {
      // to remove un selected id form object
      const {
        [userId]: {},
        ...updatedSaveSettings
      } = saveSettings;
      setSaveSettings(updatedSaveSettings);
    }
  }

  // checks for users list loading
  if (usersListLoading) {
    return (
      <div className="d-flex justify-content-center my-2">
        <Spinner />
      </div>
    );
  }

  // checks for userList
  if (!usersList.length) {
    return null;
  }

  return (
    <div className="form-group d-flex">
      <label htmlFor="staticEmail" className="col-2 px-0 col-form-label">
        Contacts
      </label>

      {/* Users name and email */}
      <div className="d-flex flex-column mt-2 ">
        {usersList.map((user, i) => (
          <UserSelectionSection
            key={i}
            user={user}
            handleUsers={handleUsers}
            saveSettings={saveSettings}
          />
        ))}
      </div>
    </div>
  );
}

/**
 * Campaign Confirmation Form
 */
export default function CampaignConfirmationForm({ campaign = {} }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaignId } = useParams();

  // data
  const { title: campaignTitle = "" } = campaign || {};

  // States
  const [title, setTitle] = useState(campaignTitle);
  const [buyerId, setBuyerId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [saveSettings, setSaveSettings] = useState({});

  // clear data
  useEffect(() => {
    clearPreviousData();
  }, []);

  // clear data function
  function clearPreviousData() {
    setTitle(campaignTitle);
    setBuyerId("");
    setBuyerName("");
    setSaveSettings({});
    dispatch(clearBuyerUsersList());
  }

  // Submit fn
  function handleSubmitForm() {
    dispatch(
      updateCampaignAndSaveSettings(
        title,
        buyerId,
        campaignId,
        saveSettings,
        history
      )
    );
  }

  // disable send button if any one of the field is empty
  const disableSendButton =
    !title || !buyerId || Object.keys(saveSettings).length < 1;

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.sendConfirmationForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        {/* Modal content */}

        <div className="modal-content">
          {/* modal title */}
          <ModalTitle />

          <div className="modal-body px-5">
            {/* Campaign Name */}
            <CampaignName title={title} setTitle={setTitle} />

            {/* Buyer Name  */}
            <BuyerName
              buyerName={buyerName}
              setBuyerName={setBuyerName}
              setBuyerId={setBuyerId}
            />

            {/* Contacts */}
            <Contacts
              saveSettings={saveSettings}
              setSaveSettings={setSaveSettings}
            />
          </div>

          {/* Modal Footer */}
          <ModalFooter
            handleSubmitForm={handleSubmitForm}
            disableSendButton={disableSendButton}
            handleClearForm={clearPreviousData}
          />
        </div>
      </div>
    </div>
  );
}
