import { useSelector } from "react-redux";

// Utils & Constants
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import { constructTotalImpAndCost } from "../../utils/campaignPlanningUtil";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";

// Components
import PerformanceCards from "../../components/performance-card/PerformanceCard";

// Page Functions

/**
 * This funtion calculates the stretch count of the media selected..
 * @param {*} campaignMedia
 * @returns
 */
function getSelectedRoadCount(campaignMedia) {
  const visitedRoadStretchId = {};
  return campaignMedia.reduce((acc, eachMedia) => {
    const { roadStretchId } = eachMedia;
    acc = visitedRoadStretchId[roadStretchId] ? acc : acc + 1;
    visitedRoadStretchId[roadStretchId] = true;
    return acc;
  }, 0);
}

function constructSitesOrSegmentMessage(
  campaignPlan,
  sitesCount,
  selectedRoadStretchCount
) {
  const card = {
    subValue: "",
  };

  const totalSelectedStretchCount = Object.keys(
    campaignPlan.roadStretchOtsMap
  ).length;

  const mediaSelectedInAllStretches =
    totalSelectedStretchCount === selectedRoadStretchCount;

  card.subValue = (
    <div className="d-flex mt-n2">
      {/* <div>
        <p>Stretches</p>
        <h3>{totalSelectedStretchCount}</h3>
      </div> */}
      <div className=" ml-5">
        <p>Media Sites</p>
        <h3>{sitesCount || 0}</h3>
      </div>
      <div className=" ml-5">
        <p>Selection Status</p>
        <h3>
          {`${selectedRoadStretchCount} / ${totalSelectedStretchCount}`}
          <button className="btn rounded-lg px-2 py-1 border shadow-none ml-2">
            <i
              className={`fa ${
                mediaSelectedInAllStretches
                  ? "fa-check text-success"
                  : "fa-minus text-secondary"
              }`}
            ></i>
          </button>
        </h3>
      </div>
    </div>
  );
  return card;
}

function getCardInfo(cityPlan, campaignMedia, roadStretchMap, tgName) {
  const {
    roadStretchOtsMap,
    genericCPM,
    targetGroupCPM,
    startTimestamp,
    endTimestamp,
  } = cityPlan;

  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);
  const selectedRoadStretches = Object.keys(roadStretchOtsMap).reduce(
    (acc, eachStretchKey) => {
      acc[eachStretchKey] = roadStretchMap[eachStretchKey];
      return acc;
    },
    {}
  );

  const { genericImpressions, specificImpressions, specificCost, genericCost } =
    constructTotalImpAndCost(
      selectedRoadStretches,
      roadStretchOtsMap,
      totalDays,
      targetGroupCPM,
      genericCPM
    );
  const totalEstCost = specificCost ? specificCost + genericCost : genericCost;

  // Count of stretches of which at-least on media is selected..
  const selectedRoadStretchCount = getSelectedRoadCount(campaignMedia);
  const segmentOrMediaCount = constructSitesOrSegmentMessage(
    cityPlan,
    campaignMedia.length,
    selectedRoadStretchCount
  );

  const cardInfo = [
    segmentOrMediaCount,
    {
      title: "Dates",
      value: dateString,
      subValue: `Duration : ${totalDays} days`,
    },
    {
      title: "Impressions (Estimated)",
      value: `${
        tgName ? `${tgName} : ${toLocaleString(specificImpressions)}` : ""
      }`,
      subValue: (
        <p className={!tgName && "h4"}>
          General: {toLocaleString(genericImpressions)}
        </p>
      ),
    },
    {
      title: "Total Est Cost",
      value: `Rs. ${toStringWithRoundUp(totalEstCost)}`,
    },
  ];

  return cardInfo;
}

/**
 * CityView Cards Component
 */
function CityViewCards({ tgName, campaign, cityPlan, campaignMedia }) {
  // RoadStretches
  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  if (
    Object.keys(cityPlan).length < 1 ||
    !campaign ||
    Object.keys(roadStretchMap) < 1
  ) {
    return null;
  }

  const cardInfo = getCardInfo(cityPlan, campaignMedia, roadStretchMap, tgName);

  const { infoByCity = {} } = campaign;
  const { cityId } = cityPlan;

  const { cityName } = infoByCity[cityId] || "";

  return (
    <>
      <h4>{cityName}</h4>
      <div className="row mx-0">
        {cardInfo.map((info, i) => (
          <PerformanceCards info={info} key={i} className={`col-3`} />
        ))}
      </div>
    </>
  );
}

export default CityViewCards;
