import moment from "moment";

// Constants and Utils
import {
  calculateAdjustedMop,
  calculatePriceFactor,
} from "../../utils/PriceFactorUtil";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import {
  DATE_FORMATS,
  DurationConstants,
  MediaSourceTypeStrings,
} from "../../../constants/GeneralConstants";
import { getMediaCostForCampaign } from "../../../utils/MediaUtils";

/**
 * Function which returns the initialState for the media Available dates
 *
 * 1) For existing media (campaign media) ==> we take from it
 * 2) If it is SellerMedia we will take the campaign Plan dates
 * @returns {startDate: value, endDate: value}
 */
export function getInitialDatesState(campaignMedia, campaignPlan) {
  if (!campaignMedia && !campaignPlan) {
    return { startDate: "", endDate: "" };
  }

  const { startTimestamp, endTimestamp } = campaignMedia
    ? campaignMedia
    : campaignPlan;

  return {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
}

function getOtsDetails(roadOts, roadStretch, duration) {
  // Specific Ots Details
  const otsDetails = {
    genericOts: "",
    genericOtsLit: "",
    targetOts: "",
    targetOtsLit: "",
  };
  if (roadOts.targetOts || roadOts.targetOtsLit) {
    const { genericOts, genericOtsLit, targetOts, targetOtsLit } = roadOts;
    otsDetails.genericOts = genericOts;
    otsDetails.genericOtsLit = genericOtsLit;
    otsDetails.targetOts = targetOts;
    otsDetails.targetOtsLit = targetOtsLit;
  } else {
    const { genericOts, genericOtsLit, targetOts, targetOtsLit } = roadStretch;
    otsDetails.genericOts = genericOts;
    otsDetails.genericOtsLit = genericOtsLit;
    otsDetails.targetOts = targetOts;
    otsDetails.targetOtsLit = targetOtsLit;
  }

  // Total Ots calculations
  const otsGeneric =
    duration *
    (otsDetails.genericOtsLit
      ? otsDetails.genericOtsLit
      : otsDetails.genericOts);

  const otsTarget =
    duration *
    (otsDetails.targetOtsLit ? otsDetails.targetOtsLit : otsDetails.targetOts);

  const otsTotal = otsTarget ? otsGeneric + otsTarget : otsGeneric;
  return { otsGeneric, otsTarget, otsTotal };
}

export function getInitialPrices(
  cityPlan = {},
  roadStretchId,
  media = {},
  roadOts = {},
  duration,
  mediaPriceInfo = {}
) {
  const { targetGroupCPM, genericCPM, roadStretchOtsMap = {} } = cityPlan || {};
  const { minOperatingPrice: mop, occupancyAvg: occupancy } =
    mediaPriceInfo || {};

  const { pricing = {}, ltsSummary } = media || {};

  // Ots Details
  const { otsGeneric, otsTarget, otsTotal } = getOtsDetails(
    roadOts,
    roadStretchOtsMap[roadStretchId],
    duration
  );

  // Price Factor based on Lts
  const priceFactor = calculatePriceFactor(ltsSummary);
  const pricePerDay = pricing.price / DurationConstants.THIRTY_DAYS;

  // Mop Calculation..
  const adjMop = calculateAdjustedMop(mop, occupancy, duration);
  const pricePerImp = adjMop / otsTotal;

  // Calculated suggested, seller and buyer prices
  const suggestedPrice = otsTarget
    ? (pricePerImp * (targetGroupCPM / 100) * otsTarget +
        pricePerDay * (genericCPM / 100) * otsGeneric) *
      priceFactor
    : pricePerDay * (genericCPM / 100) * otsTotal * priceFactor;

  return {
    totalOts: toLocaleString(otsTotal),
    suggestedPrice,
    mop: adjMop,
  };
}

export function constructCampaignMediaBean(
  dateObj,
  media = {},
  priceMode,
  sellerPrice,
  price,
  roadStretchId
) {
  const { mediaId, ltsSummary = {}, pricing = {} } = media || {};
  const { price: mediaPrice } = pricing || {};

  // DD-MM-YYYY
  const dateFormat = DATE_FORMATS.date_month_year;
  const startDateStr = moment(dateObj.startDate).format(dateFormat);
  const endDateStr = moment(dateObj.endDate).format(dateFormat);

  return {
    startDateStr,
    endDateStr,
    mediaId,
    priceMode,
    priceFactor: calculatePriceFactor(ltsSummary),
    roadStretchId,
    sellerPrice: new Number(sellerPrice.replace(/,/g, "")),
    // price: new Number(price.replace(/,/g, "")),
    price: mediaPrice,
    mediaTypeStr: MediaSourceTypeStrings.orgMedia,
  };
}

// get City Specific MediaList
export function getCitySpecificMediaList(mediaList = [], cityId) {
  if (mediaList.length === 0) {
    return [];
  }

  // filtering city specific medias
  const citySpecificMediaList = mediaList.filter(
    (media) => media.cityId === cityId
  );

  return citySpecificMediaList;
}

// get Road Specific MediaList
export function getRoadSpecificMediaList(roadMedias = [], mediaList = []) {
  if (roadMedias.length === 0) {
    return [];
  }

  // road media ids
  const roadMediaIds = roadMedias.map((roadMedia) => roadMedia.id);

  // filtering road specific medias
  const roadSpecificMediaList = mediaList.filter((media) =>
    roadMediaIds.includes(media.id)
  );

  return roadSpecificMediaList;
}

// get Static And Dooh Sites Count
export function getStaticAndDoohSitesCount(mediaList = []) {
  if (mediaList.length === 0) {
    return { static: 0, DOOH: 0 };
  }

  // media groups
  const mediaGroupsArray = mediaList.map((media) => media.mediaGroup);

  const requiredMediaGroupsCountMap = {};
  mediaGroupsArray.forEach((group) => {
    // DOOH sites count
    if (group === "DOOH") {
      requiredMediaGroupsCountMap[group] =
        (requiredMediaGroupsCountMap[group] || 0) + 1;
      return;
    }

    // all other sites count as "static sites"
    requiredMediaGroupsCountMap["static"] =
      (requiredMediaGroupsCountMap["static"] || 0) + 1;
  });

  return requiredMediaGroupsCountMap;
}

// get Sites Total Impressions And Cost
export function getSitesTotalImpressionsAndCost(mediaList = []) {
  if (mediaList.length === 0) {
    return { genericImpressions: 0, tgImpressions: 0, totalEstCost: 0 };
  }

  const totalOtsAndCost = mediaList.reduce(
    (acc, media) => {
      const { otsSummary = {}, pricing = {}, duration } = media || {};
      const { ots, targetOts } = otsSummary || {};

      // Total Impressions..
      if (ots) {
        acc.genericImpressions = acc.genericImpressions + ots;
      }
      if (targetOts) {
        acc.tgImpressions = acc.tgImpressions + targetOts;
      }

      // Calculation of EstPrice
      const estCost = getMediaCostForCampaign(pricing, duration);
      if (estCost) {
        acc.totalEstCost = acc.totalEstCost + estCost;
      }

      return acc;
    },
    {
      genericImpressions: 0,
      tgImpressions: 0,
      totalEstCost: 0,
    }
  );

  return totalOtsAndCost;
}

// get Selected Stretches And Media Count
export function getSelectedStretchesAndMediaCount(
  cityPlan = {},
  roadStretchDetailsMap = {}
) {
  // city plan
  const { roadStretchOtsMap = {} } = cityPlan || {};
  const stretchIds = Object.keys(roadStretchOtsMap);

  // if no stretches ids present
  if (stretchIds.length === 0) {
    return {
      selectedStretchesMap: {},
      totalStretchesCount: 0,
      totalMediaCount: 0,
    };
  }

  // compute data for mediaCount
  const result = stretchIds.reduce(
    (acc, id) => {
      if (roadStretchDetailsMap[id]) {
        const stretch = roadStretchDetailsMap[id];
        acc.selectedStretchesMap[id] = { ...stretch };
        acc.totalStretchesCount += 1;
        acc.totalMediaCount += stretch.mediaCount;
      }
      return acc;
    },
    { selectedStretchesMap: {}, totalStretchesCount: 0, totalMediaCount: 0 }
  );

  return result;
}

// get Selected Media Count :: selectedMediaBooleanMap : { mediaId: true, mediaId: false }
export function getSelectedMediaCount(selectedMediaBooleanMap = {}) {
  // if no data present
  if (Object.keys(selectedMediaBooleanMap).length === 0) {
    return 0;
  }

  const selectedMediaCount = Object.keys(selectedMediaBooleanMap).reduce(
    (acc, mediaId) => {
      if (!selectedMediaBooleanMap[mediaId]) {
        return acc;
      }
      acc = acc + 1;
      return acc;
    },
    0
  );

  return selectedMediaCount;
}

// get road specific Selected Media Count :: selectedMediaBooleanMap : { mediaId: true, mediaId: false }
export function getRoadSpecificSelectedMediaCount(
  roadMedias = [],
  selectedMediaBooleanMap = {}
) {
  // if no data present
  if (roadMedias.length === 0) {
    return 0;
  }

  // road media ids map
  const roadMediaIdsMap = roadMedias.reduce((acc, eachMedia) => {
    acc[eachMedia.mediaId] = true;
    return acc;
  }, {});

  const roadSelectedMediaCount = Object.keys(selectedMediaBooleanMap).reduce(
    (acc, mediaId) => {
      if (roadMediaIdsMap[mediaId] && selectedMediaBooleanMap[mediaId]) {
        acc = acc + 1;
      }
      return acc;
    },
    0
  );

  return roadSelectedMediaCount;
}
