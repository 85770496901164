import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";

export const selectPoiBrand = (cityId, cityBBox, brandId) => ({
  type: Poi.GET_BRAND_INFO,
  payload: { cityId, cityBBox, brandId },
});

export const unSelectPoiBrands = (brandIds) => ({
  type: Poi.REMOVE_BRANDS_INFO,
  payload: { brandIds },
});

export const addInfluenceCircleMarkers = () => ({
  type: Poi.SHOW_POI_INFLUENCE_CIRCLE_MARKERS,
});

export const removeInfluenceCircleMarkers = () => ({
  type: Poi.REMOVE_POI_INFLUENCE_CIRCLE_MARKERS,
});

export const setInfluenceCircleRadius = (radius) => ({
  type: Poi.SET_INFLUENCE_CIRCLE_RADIUS,
  payload: { radius },
});

export const resetBrandInfo = () => ({
  type: Poi.RESET_BRANDS_INFO,
});

export const resetPoiInfluenceCircleRadius = () => ({
  type: Poi.RESET_POI_INFLUENCE,
});
