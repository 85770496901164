/* eslint-disable no-case-declarations */
import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";

//initial state
const initialState = {
  sectorToBrandMap: {},
  brandIds: {},
  brandData: {},
  getBrandInfoLoading: {},
  getSectorInfoLoading: false,
  getSectorInfoErr: "",
  getBrandInfoErr: "",
  cityId: "",

  // toggle influence circle markers
  showInfluenceCircle: false,
  influenceCircleRadius: 500,
};

function appendBrandData(brandIds, brandData, sectorToBrandMap, payload) {
  const { brandId, brandInfo } = payload;

  const newBrandIds = { ...brandIds, [brandId]: { selected: true } };
  const newBrandData = { ...brandData, [brandId]: brandInfo };
  const newSectorToBrandMap = { ...sectorToBrandMap };

  // map brandId to sectorId
  if (!newSectorToBrandMap[newBrandData.type]) {
    newSectorToBrandMap[newBrandData.type] = [brandId];
  } else {
    newSectorToBrandMap[newBrandData.type].push(brandId);
  }

  return { brandIds: newBrandIds, brandData: newBrandData };
}

function removeBrandData(currentBrandIds, currentBrandData, payload) {
  const { brandIds } = payload;

  const updatedBrandIds = { ...currentBrandIds };
  const updatedBrandData = { ...currentBrandData };

  brandIds.forEach((brandId) => {
    delete updatedBrandIds[brandId];
    delete updatedBrandData[brandId];
  });

  return {
    currentBrandIds: updatedBrandIds,
    currentBrandData: updatedBrandData,
  };
}

const poiSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Poi.GET_BRAND_INFO:
      return {
        ...state,
        getBrandInfoLoading: {
          ...state.getBrandInfoLoading,
          [action.payload.brandId]: true,
        },
      };

    case Poi.GET_BRAND_INFO_SUCCESS:
      const brandIds =
        state.cityId === action.payload.cityId ? state.brandIds : {};
      const updatedBrandState = appendBrandData(
        brandIds,
        state.brandData,
        state.sectorToBrandMap,
        action.payload
      );
      return {
        ...state,
        getBrandInfoLoading: {
          ...state.getBrandInfoLoading,
          [action.payload.brandId]: false,
        },
        brandIds: updatedBrandState.brandIds,
        brandData: { ...updatedBrandState.brandData },
        cityId: action.payload.cityId,
      };

    case Poi.GET_BRAND_INFO_FAILURE:
      return {
        ...state,
        getBrandInfoLoading: {
          ...state.getBrandInfoLoading,
          [action.payload.brandId]: false,
        },
        getBrandInfoErr: action.payload,
      };

    case Poi.REMOVE_BRANDS_INFO: {
      const { currentBrandIds, currentBrandData } = removeBrandData(
        state.brandIds,
        state.brandData,
        action.payload
      );
      return {
        ...state,
        rmBrandLoading: false,
        brandIds: currentBrandIds,
        brandData: { ...currentBrandData },
      };
    }

    case Poi.RESET_BRANDS_INFO: {
      return {
        ...state,
        brandIds: {},
        brandData: {},
        sectorToBrandMap: {},
      };
    }

    // Toggle Influence Circle Markers
    case Poi.SHOW_POI_INFLUENCE_CIRCLE_MARKERS:
      return {
        ...state,
        showInfluenceCircle: true,
      };

    case Poi.REMOVE_POI_INFLUENCE_CIRCLE_MARKERS:
      return {
        ...state,
        showInfluenceCircle: false,
      };

    case Poi.SET_INFLUENCE_CIRCLE_RADIUS:
      return {
        ...state,
        influenceCircleRadius: action.payload.radius,
      };

    case Poi.RESET_POI_INFLUENCE:
      return {
        ...state,
        showInfluenceCircle: false,
        influenceCircleRadius: 500,
      };

    default:
      return state;
  }
};

export default poiSelectionReducer;
