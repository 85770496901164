// Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { formatText } from "../../../common-utils/string-utils/StringUtils";

// Selection Overview Card
export default function SelectionOverviewCard({
  data = [],
  containerClassName = "",
}) {
  return (
    <div className={`border p-3 overview-container ${containerClassName}`}>
      <div className="d-flex justify-content-between flex-wrap">
        {data.map(({ title, value }) => (
          <div key={title} className="mr-2">
            <h5>{title}</h5>
            <h3 title={value}>
              {value === 0 ? 0 : formatText(toStringWithRoundUp(value))}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
