import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { removeCampaignPlanFilter } from "../../../actions/campaign-plan/CampaignPlanActions";

// Utils and Constants
import { constructDateString } from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";

// Components
import Chips from "../../../components/Chips/Chips";

// Page Constants
const filterTypes = {
  zone: "zone",
  mediaType: "mediaType",
  routeType: "routeType",
};

// Page Functions
// construct Zone Chips Data
export function constructZoneChipsData(zones = [], dataType) {
  if (zones.length === 0) {
    return [];
  }

  // when response have full information
  const constructedZones = zones.map((eachData) => {
    const { cityName, zoneId, zoneName } = eachData;
    return {
      id: zoneId,
      label: `${cityName} - ${zoneName}`,
      type: dataType,
    };
  });

  return constructedZones;
}

// construct Chips Data
function constructChipsData(data = [], dataType = "") {
  if (data.length === 0) {
    return [];
  }

  const constructedChipsData = data.map((eachData) => ({
    id: eachData,
    label: eachData,
    type: dataType,
  }));

  return constructedChipsData;
}

// construct Filter Object
function constructFilterObject(item = {}) {
  const { id, type } = item || {};

  // return according to filter which want to remove
  if (type === filterTypes.zone) {
    return { zoneId: id };
  }

  if (type === filterTypes.mediaType) {
    return { [filterTypes.mediaType]: id };
  }

  if (type === filterTypes.routeType) {
    return { [filterTypes.routeType]: id };
  }

  return {};
}

/**
 * Filters And Selection Chips Card
 */
export default function FiltersAndSelectionChipsCard({
  campaign = {},
  cityPlan = {},
  containerClassName = "",
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // remove Campaign Plan Filter Loading
  const removeFilterLoading = useSelector(
    (state) => state.campaignPlan.removeCampaignPlanFilterLoading
  );

  // data
  const { infoByCity = {} } = campaign || {};
  const {
    cityId,
    startTimestamp,
    endTimestamp,
    mediaTypes = [],
    routeTypes = [],
    zones = [],
  } = cityPlan || {};

  const { cityName = "" } = infoByCity[cityId] || {};
  const durationDateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  // applied filters data
  const constructedZones = constructZoneChipsData(zones, filterTypes.zone);
  const constructedMediaTypes = constructChipsData(
    mediaTypes,
    filterTypes.mediaType
  );
  const constructedRouteTypes = constructChipsData(
    routeTypes,
    filterTypes.routeType
  );

  const appliedFilterData = [
    {
      title: "Zone",
      list: constructedZones,
    },
    {
      title: "Media Type",
      list: constructedMediaTypes,
    },
    {
      title: "Arterial Route",
      list: constructedRouteTypes,
    },
    {
      title: "Touch Point",
      list: [],
    },
    {
      title: "Target Group",
      list: [],
    },
  ];

  // handle Filter Removal
  function handleFilterRemoval(_id, item) {
    // construct filter object for "delete api  => params"
    const filterObject = constructFilterObject(item);

    // api call
    dispatch(removeCampaignPlanFilter(campaignId, filterObject));
  }

  return (
    <div className={`col-12 col-xl-6 mt-3 mt-xl-0 ${containerClassName}`}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h5>City : {cityName}</h5>
          {removeFilterLoading && (
            <span className="ml-3 bg-alt px-3 mt-n2 text-primary rounded-lg border">
              Please Wait ...
            </span>
          )}
        </div>
        <h5>{durationDateString}</h5>
      </div>
      <div className="border p-3 overview-container overflow-x-auto">
        <div className="d-flex">
          {appliedFilterData.map((data, i) => {
            const { title, list = [] } = data;
            const selectedItemsPresent = list.length > 0;
            const noDataMessage = !selectedItemsPresent ? "Not Selected" : "";
            return (
              <div className="col chips-container" key={i}>
                <Chips
                  title={title}
                  selectedItems={list}
                  headerClassName="sticky-top"
                  containerClassName="scrollable-container flex-column"
                  noDataMessage={noDataMessage}
                  onDeselect={handleFilterRemoval}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
