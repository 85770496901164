// Utils & constants
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { PerformanceCardWidth } from "../../../constants/CssConstants";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";

// Components
import PerformanceCards from "../../components/performance-card/PerformanceCard";
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";

// Page Functions
function constructSitesOrCityMessage(infoByCity, sitesCount) {
  const card = {
    subValue: "",
  };

  const cityCount = Object.keys(infoByCity).length;

  card.subValue = (
    <div className="d-flex mt-n2">
      <div>
        <p>Cities</p>
        <h3>{cityCount}</h3>
      </div>
      <div className=" ml-5">
        <p>Media Sites</p>
        <h3>{sitesCount || 0}</h3>
      </div>
    </div>
  );
  return card;
}

function getCardInfo(campaign, campaignPlanSummary, sitesCount, tgName) {
  const {
    genericOtsTotal,
    genericOtsLitTotal,
    targetOtsTotal,
    targetOtsLitTotal,
    estimatedCostTotal,
    startTimestamp,
    endTimestamp,
  } = campaignPlanSummary;

  const { infoByCity = {} } = campaign;

  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);

  const otsSpecific = targetOtsLitTotal ?? targetOtsTotal;
  const otsGeneric = genericOtsLitTotal ?? genericOtsTotal;
  const cityOrMediaCount = constructSitesOrCityMessage(infoByCity, sitesCount);

  const cardInfo = [
    cityOrMediaCount,
    {
      title: "Dates",
      value: dateString,
      subValue: `Duration : ${totalDays} days`,
    },
    {
      title: "Impressions (Estimated)",
      value: `${
        tgName ? `${tgName} : ${toLocaleString(otsSpecific * totalDays)}` : ""
      }`,
      subValue: (
        <p className={!tgName && "h4"}>
          General: {formatText(toLocaleString(otsGeneric * totalDays))}
        </p>
      ),
    },
    {
      title: "Total Est Cost",
      value: `Rs. ${toStringWithRoundUp(totalDays * estimatedCostTotal)}`,
    },
  ];

  return cardInfo;
}

/**
 * Campaign Cards Component
 */
function CampaignViewCards({
  tgName,
  campaign,
  campaignPlanSummary,
  mediaCount,
}) {
  if (Object.keys(campaignPlanSummary).length < 1 || !campaign) {
    return null;
  }

  const cardInfo = getCardInfo(
    campaign,
    campaignPlanSummary,
    mediaCount,
    tgName
  );

  return (
    <>
      <h4>{"Overview"}</h4>
      <div className="row mx-0">
        {cardInfo.map((info, i) => (
          <PerformanceCards
            info={info}
            key={i}
            className={`${PerformanceCardWidth[i]} performance-card`}
          />
        ))}
      </div>
    </>
  );
}

export default CampaignViewCards;
