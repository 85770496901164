import { DivIcon } from "leaflet";
import { Marker, Polyline } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Actions
import {
  openMediaInfo,
  selectMedia,
  unSelectMedia,
} from "../../actions/media-selection/MediaSelectionActions";
import {
  addCpmPriceMediaImpact,
  addFixedPriceMediaImpact,
  removeCpmPriceMediaImpact,
  removeFixedPriceMediaImpact,
} from "../../actions/SubmissionImpactActions";

// Constants and Utils
import { KeysToMediaType, MapZoom } from "../../../constants/GeneralConstants";
import { CPM } from "../../../constants/PriceMode";
import {
  constructCampaignMediaBean,
  getInitialDatesState,
  getInitialPrices,
} from "./MediaContainerUtil";
import { getDifferenceInDaysOfDateObject } from "../../../common-utils/date-utils/DateUtils";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import {
  AdditionalButtonState,
  AvailableDateAndOtsAndCPICell,
  BuyerPriceCell,
  MarginValueCell,
  MediaImage,
  MediaInfo,
  MediaTag,
  PricingModeCell,
  SellerEarningCell,
} from "./MediaContainerCells";

// Reusable InfoItem Component
function InfoItem({ label, value }) {
  return (
    <div className="col-4 pl-0">
      <h6 className="font-weight-bold">{label}</h6>
      <p>{value}</p>
    </div>
  );
}

function DOOHMediaInfo({ media }) {
  const {
    loopTiming = "3 min",
    slotTiming = "10 sec",
    costPerMonth = "10,00,000",
    costPerSpot = "1000",
    spotsPerDay = "320",
    spotsPerMonth = "9,600",
    ops = "16",
  } = media || {};

  const slotDetails = `${slotTiming} / ${loopTiming}`;

  const mediaInfo = [
    { label: "Loop Timing", value: loopTiming },
    { label: "Slot Timing", value: slotTiming },
    { label: "Slot Details", value: slotDetails },
    { label: "Cost per Spot", value: costPerSpot },
    { label: "Cost per Month", value: costPerMonth },
    { label: "Cost per Audience IMP", value: "1000" },
    { label: "Spots per Day", value: spotsPerDay },
    { label: "Spots per Month", value: spotsPerMonth },
    { label: "Daily Hours of Ops", value: ops },
  ];

  return (
    <div className="d-flex flex-wrap justify-content-between">
      {mediaInfo.map(({ label, value }) => (
        <InfoItem key={label} label={label} value={value} />
      ))}
    </div>
  );
}

function MaxTrafficInfo({ media }) {
  return (
    <>
      <p className="mb-0">
        <b> Max Traffic:</b> 8 - 10 am | 12 - 2 pm | 4 - 6 pm
      </p>
      <p className="mb-0">
        For Automatic ad Scheduling
        <a href="" className="px-2">
          Click here
        </a>
        for Programmatic Buys
      </p>
    </>
  );
}

function MediaDetails({ roadStretchId, media = {}, cityPlan = {}, tag }) {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // State to store the priceMode( CPM or FIXED) selected
  const [priceMode, setPriceMode] = useState(CPM);

  // State to store the input buyerPrice
  const [buyerPrice, setBuyerPrice] = useState("");

  // State to store the input sellerPrice
  const [sellerPrice, setSellerPrice] = useState("");

  // media data
  const { mediaGroup = "", mediaId, isCpmEnabled, pricing = {} } = media || {};
  const { price } = pricing || {};
  const IsDOOH = mediaGroup === KeysToMediaType.DOOH;

  // plan data
  const { roadStretchOtsMap = {} } = cityPlan || {};
  const otsSplit = roadStretchOtsMap[roadStretchId];

  const campaignMedia = useSelector(
    (state) => state.mediaSelection.mediaIdToCampaignMedia[mediaId]
  );

  // Media Pricing Info
  const mediaPriceInfo =
    useSelector((state) => state.orgMediaPrice.mediaPriceInfo[mediaId]) || {};

  // Media Info Display State
  const displayMedia =
    useSelector(
      (state) => state.mediaSelection.openCloseMediaDetails[mediaId]
    ) || false;

  // State for storing the media available dates
  const [dateObj, setDuration] = useState(
    getInitialDatesState(campaignMedia, cityPlan)
  );
  const duration = getDifferenceInDaysOfDateObject(dateObj);

  const initialPriceDetails = getInitialPrices(
    cityPlan,
    roadStretchId,
    media,
    otsSplit,
    duration,
    mediaPriceInfo
  );

  const isMediaSelected = useSelector(
    (state) => state.mediaSelection.selectedMedia[mediaId]
  );

  // to disable the add and remove media button when the api call is in pending status
  const selectUnSelectMediaLoading = useSelector(
    (state) => state.mediaSelection.selectUnSelectMediaLoading[mediaId]
  );

  // Function which dispatches respective submission impact actions based on priceMode
  function dispatchPriceModeActions(
    isCpmEnabled,
    priceMode,
    selectMedia = true
  ) {
    if (!selectMedia) {
      dispatch(removeCpmPriceMediaImpact(mediaId));
      dispatch(removeFixedPriceMediaImpact(mediaId));
      return;
    }

    if (isCpmEnabled || priceMode === CPM) {
      dispatch(addCpmPriceMediaImpact(mediaId, otsSplit, duration));
      dispatch(removeFixedPriceMediaImpact(mediaId));
      return;
    }

    dispatch(addFixedPriceMediaImpact(mediaId, otsSplit, price, duration));
    dispatch(removeCpmPriceMediaImpact(mediaId));
  }

  // Function which initiates when media is selected
  function addMedia() {
    // dispatchPriceModeActions(isCpmEnabled, priceMode);
    const campaignMediaBean = constructCampaignMediaBean(
      dateObj,
      media,
      priceMode,
      sellerPrice,
      buyerPrice,
      roadStretchId
    );
    dispatch(selectMedia(campaignId, cityId, campaignMediaBean));
  }

  // Function which initiates when media is unSelected
  function removeMedia() {
    // dispatchPriceModeActions(isCpmEnabled, priceMode, false);
    dispatch(unSelectMedia(campaignId, campaignMedia, mediaId));
  }

  if (!displayMedia) {
    return null;
  }

  return (
    <div className="d-flex align-items-stretch">
      {/* Media Tag and Information */}
      <div className="col-6 px-0 d-flex align-items-center justify-content-center">
        <MediaImage
          media={media}
          addDefaultImageClass={false}
          showNoImageMessage={true}
          iconClassName="fa-5x opacity-25"
          className="border rounded-lg media-selection-image"
        />
      </div>

      <div className="col-6 pr-0">
        <MediaTag media={media} tag={tag} />
        <MediaInfo media={media} />
        {!IsDOOH && (
          <>
            <AvailableDateAndOtsAndCPICell
              media={media}
              duration={dateObj}
              setDuration={setDuration}
              isMediaSelected={isMediaSelected}
              initialPriceDetails={initialPriceDetails}
            />
            {/* <PricingModeCell
              media={media}
              campaignMedia={campaignMedia}
              setPriceMode={setPriceMode}
              isMediaSelected={isMediaSelected}
              priceMode={priceMode}
            /> */}
          </>
        )}
        {IsDOOH && (
          <>
            <DOOHMediaInfo media={media} />
            <MaxTrafficInfo media={media} />
          </>
        )}
        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
        />
      </div>

      {/* TODO:will remove it if not required after api integration */}
      {/* <div>
        <BuyerPriceCell
          buyerPrice={buyerPrice}
          setBuyerPrice={setBuyerPrice}
          campaignMedia={campaignMedia}
          mop={initialPriceDetails.mop}
        />

        <SellerEarningCell
          sellerPrice={sellerPrice}
          setSellerPrice={setSellerPrice}
          media={media}
          campaignMedia={campaignMedia}
          suggestedPrice={initialPriceDetails.suggestedPrice}
        />
        <MarginValueCell
          sellerPrice={sellerPrice}
          buyerPrice={buyerPrice}
          campaignMedia={campaignMedia}
        />

        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
        />
      </div> */}
    </div>
  );
}

function StretchLine({ stretchPoints }) {
  const pathOptions = { color: "orange", weight: 10 };
  return (
    <Polyline pathOptions={pathOptions} positions={stretchPoints}></Polyline>
  );
}

function MediaIcons(mediaId, tag, roadMedia) {
  const mediaIcons = useSelector((state) =>
    roadMedia.reduce((acc, eachMedia) => {
      const { id } = eachMedia;
      let clsStr = "bg-danger";
      if (state.mediaSelection.selectedMedia[mediaId]) {
        clsStr = "bg-success";
      }
      acc[id] = new DivIcon({
        className:
          clsStr +
          " media-tag d-flex align-items-center justify-content-center text-white",
        html: tag,
      });
      return acc;
    }, {})
  );
  return mediaIcons[mediaId];
}

export default function MediaContainer({
  roadInfo = {},
  cityPlan = {},
  roadMedias = [],
}) {
  const dispatch = useDispatch();

  if (!roadMedias || roadMedias.length < 1) {
    return null;
  }

  const mediaIconsMap = MediaIcons;

  const { trace } = roadInfo || {};
  const center = {
    latitude: trace[0][0],
    longitude: trace[0][1],
  };

  function onMediaMarkerClick(e, mediaId) {
    dispatch(openMediaInfo(mediaId));
    e.stopPropagation();
  }

  return (
    <div className="d-flex bg-alt">
      <div className="col-4 px-0">
        <LLMap
          center={[center.latitude, center.longitude]}
          zoom={MapZoom.zoomLevel15}
        >
          <StretchLine stretchPoints={trace} />
          <TrafficFlowArrow trace={trace} />
          {roadMedias.map((eachMedia, index) => (
            <Marker
              icon={mediaIconsMap(eachMedia.mediaId, index + 1, roadMedias)}
              key={eachMedia.mediaId}
              position={[eachMedia.latitude, eachMedia.longitude]}
              eventHandlers={{
                click: (e) => onMediaMarkerClick(e, eachMedia.mediaId),
              }}
            />
          ))}
        </LLMap>
      </div>
      <div className="col-8 p-3 cont-media-selection">
        {roadMedias.map((eachMedia, index) => (
          <MediaDetails
            key={eachMedia.id}
            media={eachMedia}
            cityPlan={cityPlan}
            roadStretchId={roadInfo.id}
            tag={index + 1}
          />
        ))}
      </div>
    </div>
  );
}
