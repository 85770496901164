import {
  CreateNewRoadStretches,
  RoadStretch,
} from "../../constants/action-constants/RoadStretchActionConstants";

// Actions related to "ORG Road-Stretches"
export const getRoadStretchesOfOrg = (
  isArchived,
  cityId,
  keyWord = "",
  pageNumber,
  pageSize
) => ({
  type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG,
  payload: { isArchived, cityId, keyWord, pageNumber, pageSize },
});

export const getRoadStretchesByIds = (stretchIds) => ({
  type: RoadStretch.GET_ROAD_STRETCHES_BY_IDS,
  payload: { stretchIds },
});

export const archiveStretch = (stretchId) => ({
  type: RoadStretch.ARCHIVE_ROAD_STRETCH,
  payload: {
    stretchId,
  },
});

export const restoreStretch = (stretchId) => ({
  type: RoadStretch.RESTORE_ROAD_STRETCH,
  payload: {
    stretchId,
  },
});

export const getRoadStretchInfoById = (stretchId) => ({
  type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID,
  payload: { stretchId },
});

export const clearRoadStretchData = () => ({
  type: RoadStretch.CLEAR_ROAD_STRETCH_DATA,
});

// Action related to "Road-Stretch Creation"
export const getClosestRoadSegments = (locationStr, inclNullableTraffic) => ({
  type: CreateNewRoadStretches.GET_CLOSEST_ROAD_SG,
  payload: { locationStr, inclNullableTraffic },
});

export const resetClosestRoadSegments = () => ({
  type: CreateNewRoadStretches.RESET_CLOSEST_ROAD_SG,
});

export const addSegment = (roadSegment) => ({
  type: CreateNewRoadStretches.ADD_ROAD_SEGMENT,
  payload: { roadSegment },
});

export const removeSegment = (roadSegment) => ({
  type: CreateNewRoadStretches.REMOVE_ROAD_SEGMENT,
  payload: { roadSegment },
});

export const createRoadStretch = (stretchName, segmentIds, history) => ({
  type: CreateNewRoadStretches.CREATE_ROAD_STRETCH,
  payload: { stretchName, segmentIds, history },
});

export const resetRoadStretches = () => ({
  type: CreateNewRoadStretches.RESET,
});

export const stretchPriceRefresh = (stretchId) => ({
  type: RoadStretch.ROAD_STRETCH_PRICE_REFRESH,
  payload: { stretchId },
});

export const openRoadStretchMetaDataUpdateForm = (processDataObject) => ({
  type: RoadStretch.OPEN_ROAD_STRETCH_META_DATA_UPDATE_FORM,
  payload: { processDataObject },
});

export const closeRoadStretchMetaDataUpdateForm = () => ({
  type: RoadStretch.CLOSE_ROAD_STRETCH_META_DATA_UPDATE_FORM,
});

export const updateRoadStretchMetaData = (roadStretchBean, stretchId) => ({
  type: RoadStretch.UPDATE_ROAD_STRETCH_META_DATA,
  payload: { roadStretchBean, stretchId },
});
