import { ZonesAndSubZones } from "../constants/action-constants/ZonesAndSubZonesActionConstants";

const initialState = {
  // Zones
  zones: [],
  zonesPagination: {},
  zonesLoading: false,
  zonesError: "",

  // Sub Zones
  subZones: [],
  subZonesPagination: {},
  subZonesLoading: false,
  subZonesError: "",

  // Selected Zones & SubZones Map
  selectedZonesAndSubZonesMap: {},

  frequentlyOptedLocations: [],
  frequentlyOptedLocationsPagination: {},
  frequentlyOptedLocationsLoading: false,
  frequentlyOptedLocationsError: "",
};

// remove keys from object whose values is empty array
function filterZoneAndSubZonesMap(zonesAndSubZonesMap = {}) {
  const filteredMap = Object.keys(zonesAndSubZonesMap)
    .filter(
      (key) =>
        Array.isArray(zonesAndSubZonesMap[key]) &&
        zonesAndSubZonesMap[key].length != 0
    )
    .reduce((acc, key) => {
      acc[key] = zonesAndSubZonesMap[key];
      return acc;
    }, {});

  return filteredMap;
}

// update Zones And SubZones Map
function updateZonesAndSubZonesMap(
  zoneAndSubZoneMap = {},
  subZone = {},
  isSelect = true // key used for "select = true" & "unselect = false"
) {
  const { zoneId, subZoneId } = subZone || {};

  const clonedZonesAndSubZonesMap = { ...zoneAndSubZoneMap };

  const existingZoneIds = Object.keys(clonedZonesAndSubZonesMap);

  // append zone in map
  if (!existingZoneIds.includes(zoneId)) {
    clonedZonesAndSubZonesMap[zoneId] = [];
  }

  // update sub zones for "Select"
  if (isSelect) {
    const selectedZoneToAddSubZone = clonedZonesAndSubZonesMap[zoneId];
    const clonedSelectedZoneToAddSubZone = [...selectedZoneToAddSubZone];
    clonedSelectedZoneToAddSubZone.push(subZone);
    clonedZonesAndSubZonesMap[zoneId] = clonedSelectedZoneToAddSubZone;
  }

  // update sub zones for "Un Select"
  if (!isSelect) {
    const selectedZoneToRemoveSubZone = clonedZonesAndSubZonesMap[zoneId];
    const clonedSelectedZoneToRemoveSubZone = [...selectedZoneToRemoveSubZone];

    const subZoneIndex = clonedSelectedZoneToRemoveSubZone.findIndex(
      (subZone) => subZone.subZoneId === subZoneId
    );

    // only splice array when item is found
    if (subZoneIndex > -1) {
      clonedSelectedZoneToRemoveSubZone.splice(subZoneIndex, 1);
    }

    // update after removing sub zone
    clonedZonesAndSubZonesMap[zoneId] = clonedSelectedZoneToRemoveSubZone;
  }

  // filter map by removing zones with no sub zones
  const filteredZonesAndSubZonesMap = filterZoneAndSubZonesMap(
    clonedZonesAndSubZonesMap
  );

  // final return
  return filteredZonesAndSubZonesMap;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ZonesAndSubZones.GET_ZONES:
      return {
        ...state,
        zonesLoading: true,
        zones: [],
        zonesPagination: {},
      };

    case ZonesAndSubZones.GET_ZONES_SUCCESS: {
      const { zones, pagination } = action.payload;
      return {
        ...state,
        zonesLoading: false,
        zones,
        zonesPagination: pagination,
      };
    }

    case ZonesAndSubZones.GET_ZONES_FAILURE:
      return {
        ...state,
        zonesLoading: false,
        zonesError: action.payload,
      };

    case ZonesAndSubZones.GET_SUB_ZONES:
      return {
        ...state,
        subZonesLoading: true,
        subZones: [],
        subZonesPagination: {},
      };

    case ZonesAndSubZones.GET_SUB_ZONES_SUCCESS: {
      const { subZones, pagination } = action.payload;
      return {
        ...state,
        subZonesLoading: false,
        subZones,
        subZonesPagination: pagination,
      };
    }

    case ZonesAndSubZones.GET_SUB_ZONES_FAILURE:
      return {
        ...state,
        subZonesLoading: false,
        subZonesError: action.payload,
      };

    // Select & unSelect
    case ZonesAndSubZones.SELECT_SUB_ZONE: {
      const { subZone } = action.payload;

      const updatedZonesAndSubZonesMap = updateZonesAndSubZonesMap(
        state.selectedZonesAndSubZonesMap,
        subZone,
        true
      );

      return {
        ...state,
        selectedZonesAndSubZonesMap: updatedZonesAndSubZonesMap,
      };
    }

    case ZonesAndSubZones.UN_SELECT_SUB_ZONE: {
      const { subZone } = action.payload;

      const updatedZonesAndSubZonesMap = updateZonesAndSubZonesMap(
        state.selectedZonesAndSubZonesMap,
        subZone,
        false
      );

      return {
        ...state,
        selectedZonesAndSubZonesMap: updatedZonesAndSubZonesMap,
      };
    }

    // Fetch frequently opted locations
    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS:
      return {
        ...state,
        frequentlyOptedLocationsLoading: true,
      };

    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS: {
      const { frequentlyOptedLocations, pagination } = action.payload;
      return {
        ...state,
        frequentlyOptedLocationsLoading: false,
        frequentlyOptedLocations,
        frequentlyOptedLocationsPagination: pagination,
      };
    }

    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE:
      return {
        ...state,
        frequentlyOptedLocationsLoading: false,
        frequentlyOptedLocationsError: action.payload,
      };

    // clear selected zone & subZones map
    case ZonesAndSubZones.CLEAR_SELECTED_ZONES_AND_SUB_ZONES_MAP: {
      return {
        ...state,
        zones: [],
        subZones: [],
        frequentlyOptedLocations: [],
        selectedZonesAndSubZonesMap: {},
      };
    }

    default:
      return state;
  }
};
