import CampaignPlanningReducer from "./campaign-planning/CampaignPlanningReducer";
import CampaignPlanningRoadStretchesReducer from "./campaign-planning/CampaignPlanningRoadStretchesReducer";

import tgSpecificOtsReducer from "./tgSpecificOtsReducer";
import planningRoadSegmentsReducer from "./campaign-planning/CampaignPlanningRoadSegmentsReducer";
import planningPoiReducer from "./campaign-planning/poiReducer";
import poiModalReducer from "./campaign-planning/poiModalReducer";
import MediaSelectionReducer from "./media-selection/MediaSelectionReducer";
import TargetGroupModalReducer from "./campaign-planning/TargetGroupModalReducer";
import PoiSelectionFormReducer from "./campaign-planning/PoiSelectionFormReducer";
import TabContentPreviewFormReducer from "./campaign-planning/TabContentPreviewFormReducer";
import SubmissionImpactReducer from "./SubmissionImpactReducer";
import CreateMediaModalReducer from "./media-selection/CreateMediaModalReducer";
import CampaignConfirmModalReducer from "./campaign-review/CampaignConfirmModalReducer";
import CampaignConfirmationReducer from "./campaign-confirmation/CampaignConfirmationReducer";
import CampaignReviewReducer from "./campaign-review/CampaignReviewReducer";
import CampaignPerformanceReducer from "./campaign-performance/CampaignPerformanceReducer";

// Seller Pitching Reducers
import SingleSiteReportReducer from "./single-site-report/SingleSiteReportReducer";

// Map State
import MapStateReducer from "./map-state/MapStateReducer";

// Map View
import MapViewReducer from "./map-view/MapViewReducer";

// Zones & SubZones
import ZonesAndSubZonesReducer from "./ZonesAndSubZonesReducer";

export const proohReducers = {
  campaignPlanning: CampaignPlanningReducer,
  mediaSelection: MediaSelectionReducer,
  planningRoadStretches: CampaignPlanningRoadStretchesReducer,
  planningPoi: planningPoiReducer,
  tgSpecificOts: tgSpecificOtsReducer,
  planningRoadSegments: planningRoadSegmentsReducer,
  poiFormModal: poiModalReducer,
  targetGroupFormModal: TargetGroupModalReducer,
  poiSelectionForm: PoiSelectionFormReducer,
  tabContentPreviewForm: TabContentPreviewFormReducer,
  submissionImpact: SubmissionImpactReducer,
  createMediaModal: CreateMediaModalReducer,
  campaignConfirmModal: CampaignConfirmModalReducer,
  campaignConfirmation: CampaignConfirmationReducer,
  campaignReview: CampaignReviewReducer,
  campaignPerformance: CampaignPerformanceReducer,

  // Seller Pitching Reducer
  singleSiteReport: SingleSiteReportReducer,

  proohMapState: MapStateReducer,
  proohMapView: MapViewReducer,

  // Zones & SubZones
  zonesAndSubZones: ZonesAndSubZonesReducer,
};
