/**
 * CheckBox
 */
export default function CheckBox({
  boxStyle = "",
  checkBoxKey,
  inputBoxStyle = "",
  onChange = () => {},
  disabled = false,
  checked = true,
  showLabel = true,
  labelStyle = "",
}) {
  return (
    <div className={`form-group form-check ${boxStyle}`}>
      <input
        id={checkBoxKey}
        type="checkbox"
        className={`form-check-input cursor-pointer ${inputBoxStyle}`}
        checked={checked}
        onChange={(e) => onChange(e, checkBoxKey)}
        disabled={disabled}
      />
      {showLabel && (
        <label
          className={`form-check-label px-1 ${labelStyle}`}
          title={checkBoxKey}
          htmlFor={checkBoxKey}
        >
          {checkBoxKey}
        </label>
      )}
    </div>
  );
}
