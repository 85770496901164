import { ZonesAndSubZones } from "../../constants/action-constants/ZonesAndSubZonesActionConstants";

export const getZones = () => ({
  type: ZonesAndSubZones.GET_ZONES,
  payload: {},
});

export const getSubZones = (regionId) => ({
  type: ZonesAndSubZones.GET_SUB_ZONES,
  payload: { regionId },
});

export const selectSubZone = (subZone) => ({
  type: ZonesAndSubZones.SELECT_SUB_ZONE,
  payload: { subZone },
});

export const unSelectSubZone = (subZone) => ({
  type: ZonesAndSubZones.UN_SELECT_SUB_ZONE,
  payload: { subZone },
});

export const getFrequentlyOptedLocations = (cityId) => ({
  type: ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS,
  payload: { cityId },
});

export const clearSelectedZoneAndSubZonesMap = () => ({
  type: ZonesAndSubZones.CLEAR_SELECTED_ZONES_AND_SUB_ZONES_MAP,
});
