// Constants and Utils
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import {
  LIT_STATUS,
  LocalStorageKeys,
  OrganizationIdsMapping,
  PriceIntervalConstants,
  PriceUnitConstants,
  UrlKeys,
} from "../../../constants/GeneralConstants";
import { getItem } from "../../../utils/localstorage";
import { checkIsOrgSpecific } from "../../../utils/OrgUtils";
import { useUrlKeyCheck } from "../../utils/hooks/HooksUtil";

// Components
export function Dimensions({ media }) {
  const { type, litStatus, height, width, otsSummary = {}, region } = media;
  const { targetGroupName = "" } = otsSummary;
  const status = LIT_STATUS[litStatus];

  const typeStr = type ? `${type} | ` : "";
  const heightStr = height ? `${height}ft` : "";
  const widthStr = width ? ` x ${width}ft` : "";
  const litStatusStr = status ? ` | ${status}` : "";
  const targetGroupNameStr = targetGroupName
    ? ` | TargetGroup : ${targetGroupName}`
    : "";
  const regionStr = region ? ` | ${region}` : "";

  return `${typeStr}${heightStr}${widthStr}${litStatusStr}${regionStr}${targetGroupNameStr}`;
}

export function PriceInfo({ media = {} }) {
  const { pricing = {} } = media || {};
  const { price, priceUnit, pricingInterval } = pricing || {};

  // null price
  if (price === null || price === undefined) {
    return " | Price not available";
  }

  // price
  const formattedPrice = Number(price.toFixed(2).replace(/\.00$/, ""));
  const localeStringPrice = toLocaleString(formattedPrice);

  // price unit
  const priceUnitToDisplay =
    priceUnit === PriceUnitConstants.INR ? "Rs" : `${priceUnit}`;

  // price interval
  const pricingIntervalToDisplay =
    pricingInterval === PriceIntervalConstants.PER_MONTH
      ? "Per Month"
      : `${pricingInterval}`;

  return ` | ${priceUnitToDisplay} ${localeStringPrice} ${pricingIntervalToDisplay}`;
}

// Media Info Components
function MediaInfo({ media = {}, className = "", titleClassName = "" }) {
  // mantaray
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  // patrika
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  // no media data
  if (Object.keys(media).length === 0) {
    return null;
  }

  // check to show pricing (only for mantaray & patrika)
  const showPricing = isMantaraySpecific || isPatrikaSpecific;

  return (
    <div className={className}>
      {/* Media title */}
      <h3 className={`text-truncate ${titleClassName}`} title={media.title}>
        {media.title}
      </h3>
      <p className="h4">
        {/* Media dimensions */}
        <Dimensions media={media} />

        {/* Conditionally showing price */}
        {showPricing && <PriceInfo media={media} />}
      </p>
    </div>
  );
}

export default MediaInfo;
