import { CampaignState } from "./planningConstants";
import { Link } from "react-router-dom";

/// Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

export function addCityAction(campaignId, selectedCities, tgId, history) {
  history.push({
    pathname: constructRedirectPath(`/campaign/${campaignId}/planning`),
    search: `?_extcities=${selectedCities}&_tg=${tgId}`,
  });
}

export function continueToPlanning(campaignId, cityId, history) {
  history.push({
    pathname: constructRedirectPath(`/campaign/${campaignId}/planning`),
    search: `?_city=${cityId}`,
  });
}

export function CityRedirect({
  campaignId,
  cityId,
  state,
  displayText,
  className,
}) {
  // Continue to campaign planning
  let redirectUrl = ``;

  if (CampaignState.DRAFT === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/draft`;
  }

  if (CampaignState.REVIEW === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/review`;
  }

  if (CampaignState.CONFIRMED === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/confirm`;
  }

  if (CampaignState.SCHEDULED === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/schedule`;
  }

  if (CampaignState.LIVE === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/live`;
  }

  if (CampaignState.COMPLETED === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/complete`;
  }

  if (CampaignState.CANCELLED === state) {
    redirectUrl = `/campaign/${campaignId}/city/${cityId}/cancel`;
  }

  return (
    <Link className={className} to={constructRedirectPath(redirectUrl)}>
      {displayText}
    </Link>
  );
}

export function CampaignRedirect({ id, state }) {
  // Continue to campaign planning
  let redirectUrl = "";

  if (CampaignState.DRAFT === state) {
    redirectUrl = `/campaign/${id}/draft`;
  }

  if (CampaignState.REVIEW === state) {
    redirectUrl = `/campaign/${id}/review`;
  }

  if (CampaignState.CONFIRMED === state) {
    redirectUrl = `/campaign/${id}/confirm`;
  }

  if (CampaignState.LIVE === state) {
    redirectUrl = `/campaign/${id}/live`;
  }

  if (CampaignState.COMPLETED === state) {
    redirectUrl = `/campaign/${id}/complete`;
  }

  if (CampaignState.SCHEDULED === state) {
    redirectUrl = `/campaign/${id}/schedule`;
  }

  if (CampaignState.CANCELLED === state) {
    redirectUrl = `/campaign/${id}/cancel`;
  }

  return <Link to={constructRedirectPath(redirectUrl)}>View Details</Link>;
}
