export const ActionTypes = {
  CampaignConfirmation: {
    GET_CAMPAIGN_PLAN_MAP_IMAGE: "GET_CAMPAIGN_PLAN_MAP_IMAGE",
    GET_CAMPAIGN_PLAN_MAP_IMAGE_SUCCESS: "GET_CAMPAIGN_PLAN_MAP_IMAGE_SUCCESS",
    GET_CAMPAIGN_PLAN_MAP_IMAGE_FAILURE: "GET_CAMPAIGN_PLAN_MAP_IMAGE_FAILURE",

    RESET_CAMPAIGN_CONFIRMATION: "RESET_CAMPAIGN_CONFIRMATION",

    GET_CAMPAIGN_MEDIA: "GET_CAMPAIGN_MEDIA_CAMPAIGN_COFIRMATION",
    GET_CAMPAIGN_MEDIA_SUCCESS:
      "GET_CAMPAIGN_MEDIA_CAMPAIGN_COFIRMATION_SUCCESS",
    GET_CAMPAIGN_MEDIA_FAILURE:
      "GET_CAMPAIGN_MEDIA_CAMPAIGN_COFIRMATION_FAILURE",

    // Media Setup Monitoring Actions
    MEDIA_SETUP_MONITORING: "MEDIA_SETUP_MONITORING",
    MEDIA_SETUP_MONITORING_SUCCESS: "MEDIA_SETUP_MONITORING_SUCCESS",
    MEDIA_SETUP_MONITORING_FAILURE: "MEDIA_SETUP_MONITORING_FAILURE",
  },

  SubmissionImpact: {
    ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT:
      "ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT",

    REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT:
      "REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT",

    ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT:
      "ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT",

    REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT:
      "REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT",
  },

  MediaSelection: {
    GET_CAMPAIGN_MEDIA: "GET_CAMPAIGN_MEDIA_MEDIA_SELECTION",
    GET_CAMPAIGN_MEDIA_SUCCESS: "GET_CAMPAIGN_MEDIA_MEDIA_SELECTION_SUCCESS",
    GET_CAMPAIGN_MEDIA_FAILURE: "GET_CAMPAIGN_MEDIA_MEDIA_SELECTION_FAILURE",

    SELECT_MEDIA: "SELECT_MEDIA_MEDIA_SELECTION",
    SELECT_MEDIA_SUCCESS: "SELECT_MEDIA_MEDIA_SELECTION_SUCCESS",
    SELECT_MEDIA_FAILURE: "SELECT_MEDIA_MEDIA_SELECTION_FAILURE",

    UN_SELECT_MEDIA: "UN_SELECT_MEDIA_MEDIA_SELECTION",
    UN_SELECT_MEDIA_SUCCESS: "UN_SELECT_MEDIA_MEDIA_SELECTION_SUCCESS",
    UN_SELECT_MEDIA_FAILURE: "UN_SELECT_MEDIA_MEDIA_SELECTION_FAILURE",

    CONFIRM_CAMPAIGN_PLAN: "CONFIRM_CAMPAIGN_PLAN",
    CONFIRM_CAMPAIGN_PLAN_SUCCESS: "CONFIRM_CAMPAIGN_PLAN_SUCCESS",
    CONFIRM_CAMPAIGN_PLAN_FAILURE: "CONFIRM_CAMPAIGN_PLAN_FAILURE",

    // Create campaign Media Actions constants
    CREATE_CAMPAIGN_MEDIA: "CREATE_CAMPAIGN_MEDIA",
    CREATE_CAMPAIGN_MEDIA_SUCCESS: "CREATE_CAMPAIGN_MEDIA_SUCCESS",
    CREATE_CAMPAIGN_MEDIA_FAILURE: "CREATE_CAMPAIGN_MEDIA_FAILURE",

    // Media image upload action constants
    MEDIA_IMAGE_UPLOAD: "MEDIA_IMAGE_UPLOAD",
    MEDIA_IMAGE_UPLOAD_SUCCESS: "MEDIA_IMAGE_UPLOAD_SUCCESS",
    MEDIA_IMAGE_UPLOAD_FAILURE: "MEDIA_IMAGE_UPLOAD_FAILURE",

    // Open Media Info Details when clicked on Media Marker
    OPEN_MEDIA_INFO: "MEDIA_SELECTION_OPEN_MEDIA_INFO",
  },

  // Open and close create media modal in site selection page
  CreateMediaFormModal: {
    OPEN_MEDIA_CREATE_MODAL: "OPEN_MEDIA_CREATE_MODAL",
    CLOSE_MEDIA_CREATE_MODAL: "CLOSE_MEDIA_CREATE_MODAL",
  },

  AddMediaForm: {
    // actions for "add-planning-media" form
    OPEN_PLANNING_ADD_MEDIA_MODAL: "OPEN_PLANNING_ADD_MEDIA_MODAL",
    CLOSE_PLANNING_ADD_MEDIA_MODAL: "CLOSE_PLANNING_ADD_MEDIA_MODAL",
  },

  TargetGroupForm: {
    OPEN_TARGET_GROUP_MODAL: "OPEN_TARGET_GROUP_MODAL",
    CLOSE_TARGET_GROUP_MODAL: "CLOSE_TARGET_GROUP_MODAL",
  },

  PoiSelectionForm: {
    OPEN_POI_SELECTION_MODAL: "OPEN_POI_SELECTION_MODAL",
    CLOSE_POI_SELECTION_MODAL: "CLOSE_POI_SELECTION_MODAL",

    GET_SELECTED_POI_INFO: "GET_SELECTED_POI_INFO",
    GET_SELECTED_POI_INFO_SUCCESS: "GET_SELECTED_POI_INFO_SUCCESS",
    GET_SELECTED_POI_INFO_FAILURE: "GET_SELECTED_POI_INFO_FAILURE",
  },

  TabContentPreviewForm: {
    OPEN_TAB_CONTENT_PREVIEW_MODAL: "OPEN_TAB_CONTENT_PREVIEW_MODAL",
    CLOSE_TAB_CONTENT_PREVIEW_MODAL: "CLOSE_TAB_CONTENT_PREVIEW_MODAL",
  },

  CitySelectionForm: {
    // for "Single City Selection"
    OPEN_SINGLE_CITY_SELECTION_MODAL: "OPEN_SINGLE_CITY_SELECTION_MODAL",
    CLOSE_SINGLE_CITY_SELECTION_MODAL: "CLOSE_SINGLE_CITY_SELECTION_MODAL",

    // for "Multi City Selection"
    OPEN_MULTI_CITY_SELECTION_MODAL: "OPEN_MULTI_CITY_SELECTION_MODAL",
    CLOSE_MULTI_CITY_SELECTION_MODAL: "CLOSE_MULTI_CITY_SELECTION_MODAL",
  },

  CampaignPlanDetails: {
    // to get CampaignPlan by "CampaignId and CityId"
    GET_CAMPAIGN_PLAN: "GET_CAMPAIGN_PLAN",
    GET_CAMPAIGN_PLAN_SUCCESS: "GET_CAMPAIGN_PLAN_SUCCESS",
    GET_CAMPAIGN_PLAN_FAILURE: "GET_CAMPAIGN_PLAN_FAILURE",

    // to get CampaignPlan by "CampaignId"
    GET_CAMPAIGN_PLAN_OVERVIEW: "GET_CAMPAIGN_PLAN_OVERVIEW",
    GET_CAMPAIGN_PLAN_OVERVIEW_SUCCESS: "GET_CAMPAIGN_PLAN_OVERVIEW_SUCCESS",
    GET_CAMPAIGN_PLAN_OVERVIEW_FAILURE: "GET_CAMPAIGN_PLAN_OVERVIEW_FAILURE",
    RESET_CAMPAIGN_PLAN_DETAILS: "RESET_CAMPAIGN_PLAN_DETAILS",

    // to change campaign plan state
    UPDATE_CAMPAIGN_PLAN_STATUS: "UPDATE_CAMPAIGN_PLAN_STATUS",
    UPDATE_CAMPAIGN_PLAN_STATUS_SUCCESS: "UPDATE_CAMPAIGN_PLAN_STATUS_SUCCESS",
    UPDATE_CAMPAIGN_PLAN_STATUS_FAILURE: "UPDATE_CAMPAIGN_PLAN_STATUS_FAILURE",

    // remove campaign plan filter
    REMOVE_CAMPAIGN_PLAN_FILTER: "REMOVE_CAMPAIGN_PLAN_FILTER",
    REMOVE_CAMPAIGN_PLAN_FILTER_SUCCESS: "REMOVE_CAMPAIGN_PLAN_FILTER_SUCCESS",
    REMOVE_CAMPAIGN_PLAN_FILTER_FAILURE: "REMOVE_CAMPAIGN_PLAN_FILTER_FAILURE",
  },

  CampaignDetails: {
    GET_CAMPAIGN_BASIC_INFO: "GET_CAMPAIGN_BASIC_INFO",
    GET_CAMPAIGN_BASIC_INFO_SUCCESS: "GET_CAMPAIGN_BASIC_INFO_SUCCESS",
    GET_CAMPAIGN_BASIC_INFO_FAILURE: "GET_CAMPAIGN_BASIC_INFO_FAILURE",
    RESET_CAMPAIGN_DETAILS: "RESET_CAMPAIGN_DETAILS",

    UPDATE_CAMPAIGN_PLAN_DATES: "UPDATE_CAMPAIGN_PLAN_DATES",

    UPDATE_CAMPAIGN_STATE: "UPDATE_CAMPAIGN_STATE",
    UPDATE_CAMPAIGN_STATE_SUCCESS: "UPDATE_CAMPAIGN_STATE_SUCCESS",
    UPDATE_CAMPAIGN_STATE_FAILURE: "UPDATE_CAMPAIGN_STATE_FAILURE",

    GET_CAMPAIGN_LIST: "GET_CAMPAIGN_LIST",
    GET_CAMPAIGN_LIST_SUCCESS: "GET_CAMPAIGN_LIST_SUCCESS",
    GET_CAMPAIGN_LIST_FAILURE: "GET_CAMPAIGN_LIST_FAILURE",
  },

  PoiForm: {
    // add-poi-and-captive-area-form actions
    OPEN_POI_AND_CAPTIVE_AREA_MODAL: "OPEN_POI_AND_CAPTIVE_AREA_MODAL",
    CLOSE_POI_AND_CAPTIVE_AREA_MODAL: "CLOSE_POI_AND_CAPTIVE_AREA_MODAL",

    // for only "single-latLongString"
    GET_CLOSEST_ROAD_SG: "GET_CLOSEST_ROAD_SG_POI_FORM",
    GET_CLOSEST_ROAD_SG_SUCCESS: "GET_CLOSEST_ROAD_SG_SUCCESS_POI_FORM",
    GET_CLOSEST_ROAD_SG_FAILURE: "GET_CLOSEST_ROAD_SG_FAILURE_POI_FORM",
  },

  // this is used in campaign-planning
  RegionsData: {},

  AddPoiArea: {
    ADD_POI_AREA: "ADD_POI_AREA",
    ADD_POI_AREA_SUCCESS: "ADD_POI_AREA_SUCCESS",
    ADD_POI_AREA_FAILURE: "ADD_POI_AREA_FAILURE",
  },

  ExactLatLong: {
    GET_EXACT_LAT_LONG: "GET_EXACT_LAT_LONG",
    ADD_EXACT_LAT_LONG: "ADD_EXACT_LAT_LONG",

    // actions related to "poi-selection"
    REMOVE_EXACT_LAT_LONG: "REMOVE_EXACT_LAT_LONG",

    //Reset
    RESET_POI_SELECTION: "RESET_POI_SELECTION",
  },

  Media: {
    // actions related to media selection on map
    SELECT_MEDIA: "SELECT_MEDIA",
    UN_SELECT_MEDIA: "UN_SELECT_MEDIA",

    // Reset
    RESET_SELECTED_MEDIA: "RESET_SELECTED_MEDIA",

    GET_CAMPAIGN_MEDIA: "GET_CAMPAIGN_MEDIA",
    GET_CAMPAIGN_MEDIA_SUCCESS: "GET_CAMPAIGN_MEDIA_SUCCESS",
    GET_CAMPAIGN_MEDIA_FAILURE: "GET_CAMPAIGN_MEDIA_FAILURE",
  },

  MediaPerformance: {
    GET_MEDIA_PERFORMANCE: "GET_MEDIA_PERFORMANCE",
    GET_MEDIA_PERFORMANCE_SUCCESS: "GET_MEDIA_PERFORMANCE_SUCCESS",
    GET_MEDIA_PERFORMANCE_FAILURE: "GET_MEDIA_PERFORMANCE_FAILURE",
  },

  CampaignPerformance: {
    GET_CAMPAIGN_PERFORMANCE: "GET_CAMPAIGN_PERFORMANCE",
    GET_CAMPAIGN_PERFORMANCE_SUCCESS: "GET_CAMPAIGN_PERFORMANCE_SUCCESS",
    GET_CAMPAIGN_PERFORMANCE_FAILURE: "GET_CAMPAIGN_PERFORMANCE_FAILURE",

    GET_CAMPAIGN_PERFORMANCE_MAP: "GET_CAMPAIGN_PERFORMANCE_MAP",
    GET_CAMPAIGN_PERFORMANCE_MAP_SUCCESS:
      "GET_CAMPAIGN_PERFORMANCE_MAP_SUCCESS",
    GET_CAMPAIGN_PERFORMANCE_MAP_FAILURE:
      "GET_CAMPAIGN_PERFORMANCE_MAP_FAILURE",

    // for Campaign Performance by City ID
    GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID: "GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID",
    GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_SUCCESS:
      "GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_SUCCESS",
    GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_FAILURE:
      "GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_FAILURE",

    // live Campaign(overview) performance
    GET_CAMPAIGN_PERFORMANCE_BY_ID: "GET_CAMPAIGN_PERFORMANCE_BY_ID",
    GET_CAMPAIGN_PERFORMANCE_BY_ID_SUCCESS:
      "GET_CAMPAIGN_PERFORMANCE_BY_ID_SUCCESS",
    GET_CAMPAIGN_PERFORMANCE_BY_ID_FAILURE:
      "GET_CAMPAIGN_PERFORMANCE_BY_ID_FAILURE",
  },

  CampaignPlanner: {
    // action related to "campaign-review"
    PLAN_CAMPAIGN: "PLAN_CAMPAIGN",
    PLAN_CAMPAIGN_SUCCESS: "PLAN_CAMPAIGN_SUCCESS",
    PLAN_CAMPAIGN_FAILURE: "PLAN_CAMPAIGN_FAILURE",

    // to "set-Title" of campaign
    SET_CAMPAIGN_TITLE: "SET_CAMPAIGN_TITLE",

    // to "set-tg-cpm"
    SET_TG_CPM: "SET_TG_CPM",
    // to "set-generic-cpm"
    SET_GEN_CPM: "SET_GEN_CPM",

    // To calculate Total Imp and Est Cost
    ADD_IMP_AND_EST_COST: "ADD_IMP_AND_EST_COST",
    REMOVE_IMP_AND_EST_COST: "REMOVE_IMP_AND_EST_COST",

    CHANGE_DURATION: "CHANGE_DURATION",

    // Restore Campaign Plan
    RESTORE_CAMPAIGN_PLAN: "RESTORE_CAMPAIGN_PLAN",
    RESTORE_CAMPAIGN_PLAN_SUCCESS: "RESTORE_CAMPAIGN_PLAN_SUCCESS",
    RESTORE_CAMPAIGN_PLAN_FAILURE: "RESTORE_CAMPAIGN_PLAN_FAILURE",

    // Regions Data
    GET_REGIONS: "GET_REGIONS_PLANNING",
    GET_REGIONS_SUCCESS: "GET_REGIONS_SUCCESS_PLANNING",
    GET_REGIONS_FAILURE: "GET_REGIONS_FAILURE_PLANNING",
    RESET_REGIONS_DATA: "RESET_REGIONS_DATA",

    // TgSpecific Ots
    GET_TG_SPECIFIC_OTS: "GET_TG_SPECIFIC_OTS",
    GET_TG_SPECIFIC_OTS_SUCCESS: "GET_TG_SPECIFIC_OTS_SUCCESS",
    GET_TG_SPECIFIC_OTS_FAILURE: "GET_TG_SPECIFIC_OTS_FAILURE",

    // for all "Road-Stretches" in a CITY
    GET_TG_SPECIFIC_OTS_FOR_CITY: "GET_TG_SPECIFIC_OTS_FOR_CITY",
    GET_TG_SPECIFIC_OTS_FOR_CITY_SUCCESS:
      "GET_TG_SPECIFIC_OTS_FOR_CITY_SUCCESS",
    GET_TG_SPECIFIC_OTS_FOR_CITY_FAILURE:
      "GET_TG_SPECIFIC_OTS_FOR_CITY_FAILURE",
    RESET_TG_SPECIFIC_OTS: "RESET_TG_SPECIFIC_OTS",

    // for TgSpecificOts for "Road-Segments"
    GET_TG_SPECIFIC_OTS_FOR_SEGMENTS: "GET_TG_SPECIFIC_OTS_FOR_SEGMENTS",
    GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_SUCCESS:
      "GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_SUCCESS",
    GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_FAILURE:
      "GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_FAILURE",

    // Clear
    CLEAR_TG_SPECIFIC_OTS_FOR_SEGMENTS: "CLEAR_TG_SPECIFIC_OTS_FOR_SEGMENTS",

    // Reset
    RESET_PLAN_CAMPAIGN: "RESET_PLAN_CAMPAIGN",

    SET_TARGET_GROUP: "SET_TARGET_GROUP",
    SELECT_TG_ATTRIBUTE: "SELECT_TG_ATTRIBUTE",
    UN_SELECT_TG_ATTRIBUTE: "UN_SELECT_TG_ATTRIBUTE",

    TOGGLE_ARTERIAL_ROUTE_TYPE: "TOGGLE_ARTERIAL_ROUTE_TYPE",

    TOGGLE_MEDIA_TYPE: "TOGGLE_MEDIA_TYPE",
  },

  SingleSiteReport: {
    // to "set-tg-cpm"
    SET_TG_CPM: "SET_TG_CPM",
    // to "set-generic-cpm"
    SET_GEN_CPM: "SET_GEN_CPM",
  },

  SellerExcelUpload: {
    // upload-template
    SELLER_UPLOAD_TEMPLATE: "SELLER_UPLOAD_TEMPLATE",
    SELLER_UPLOAD_TEMPLATE_SUCCESS: "SELLER_UPLOAD_TEMPLATE_SUCCESS",
    SELLER_UPLOAD_TEMPLATE_FAILURE: "SELLER_UPLOAD_TEMPLATE_FAILURE",

    // clear excel-data
    RESET_EXCEL_DATA: "RESET_EXCEL_DATA",
  },

  SellerExcelOverview: {
    // actions to get ots of all lat-longs
    GET_OTS_OF_LAT_LONGS: "GET_OTS_OF_LAT_LONGS",
    GET_OTS_OF_LAT_LONGS_SUCCESS: "GET_OTS_OF_LAT_LONGS_SUCCESS",
    GET_OTS_OF_LAT_LONGS_FAILURE: "GET_OTS_OF_LAT_LONGS_FAILURE",
  },

  PoiNames: {
    // action related to get POI-NAMES for "autoComplete"
    GET_POI_NAMES_LIST: "GET_POI_NAMES_LIST",
    GET_POI_NAMES_LIST_SUCCESS: "GET_POI_NAMES_LIST_SUCCESS",
    GET_POI_NAMES_LIST_FAILURE: "GET_POI_NAMES_LIST_FAILURE",

    // action to clear poiNames state
    CLEAR_POI_NAMES_LIST: "CLEAR_POI_NAMES_LIST",

    // action to setPoiInfo
    STORING_POI_INFO: "STORING_POI_INFO",
    REMOVE_POI_INFO: "REMOVE_POI_INFO",
  },

  //for showing selected poi's on map view
  Poi: {
    GET_POI_INFO: "GET_POI_INFO",
    GET_POI_INFO_SUCCESS: "GET_POI_INFO_SUCCESS",
    GET_POI_INFO_FAILURE: "GET_POI_INFO_FAILURE",
  },

  SiteUpload: {
    // to download-template
    DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
    DOWNLOAD_TEMPLATE_SUCCESS: "DOWNLOAD_TEMPLATE_SUCCESS",
    DOWNLOAD_TEMPLATE_FAILURE: "DOWNLOAD_TEMPLATE_FAILURE",

    // to upload-template
    UPLOAD_TEMPLATE: "UPLOAD_TEMPLATE",
    UPLOAD_TEMPLATE_SUCCESS: "UPLOAD_TEMPLATE_SUCCESS",
    UPLOAD_TEMPLATE_FAILURE: "UPLOAD_TEMPLATE_FAILURE",

    // to reset site-upload page data
    RESET_SITE_UPLOAD_STATE: "RESET_SITE_UPLOAD_STATE",
  },

  // Open and close campaign confirm modal
  CampaignConfirmFormModal: {
    OPEN_CAMPAIGN_CONFIRM_MODAL: "OPEN_CAMPAIGN_CONFIRM_MODAL",
    CLOSE_CAMPAIGN_CONFIRM_MODAL: "CLOSE_CAMPAIGN_CONFIRM_MODAL",
  },

  CampaignReview: {
    UPDATE_CAMPAIGN_AND_SAVE_SETTINGS: "UPDATE_CAMPAIGN_AND_SAVE_SETTINGS",
    UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_SUCCESS:
      "UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_SUCCESS",
    UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_FAILURE:
      "UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_FAILURE",
  },

  ConfirmationFormModal: {
    // Open and close "Confirm-Actions-modal"
    OPEN_CONFIRMATION_MODAL: "OPEN_CONFIRMATION_MODAL",
    CLOSE_CONFIRMATION_MODAL: "CLOSE_CONFIRMATION_MODAL",
  },
};
